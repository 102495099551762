import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { ConfigVars } from "../domain/entities/ConfigVars";
import { getConfigVarsThunk, upsertConfigVarsThunk } from "./configVarsThunk";

interface ConfigVarsState {
    configVars: ConfigVars | undefined,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: ConfigVarsState = {
    configVars: undefined,
    status: 'ready'
}

const configVarsSlice = createSlice({
    name: 'configVarss',
    initialState,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<ConfigVarsState>) => {
        
        builder.addCase(getConfigVarsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getConfigVarsThunk.fulfilled, (state, action: PayloadAction<ConfigVars>) => {
            state.configVars = action.payload;
            state.status = 'ready';
        }).addCase(getConfigVarsThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(upsertConfigVarsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(upsertConfigVarsThunk.fulfilled, (state, action: PayloadAction<ConfigVars>) => {
            state.configVars = action.payload;
            state.status = 'ready';
        }).addCase(upsertConfigVarsThunk.rejected, (state) => {
            state.status = 'error';
        });
    }
});

const getConfigVars = (state: RootState) => state.configVars.configVars;
const getStatus = (state: RootState) => state.configVars.status;

export {
    getConfigVars,
    getStatus
}

export default configVarsSlice.reducer;