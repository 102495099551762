export class VehicleStatus {
    static readonly Active = new VehicleStatus('active');

    static readonly Inactive = new VehicleStatus('inactive');

    static readonly Pending = new VehicleStatus('pending');

    constructor(public readonly value: string) { }

    static getValues(): VehicleStatus[] {
        return [this.Active, this.Inactive, this.Pending];
    }
    
    static fromString(string: string): VehicleStatus {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}