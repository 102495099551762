import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { FuelType } from "../domain/entities/FuelType";
import { deleteFuelTypeThunk, findFuelTypeByIdThunk, getFuelTypesThunk, updateFuelTypeThunk, createFuelTypeThunk } from './fuelTypeThunk';

export type FuelTypeAction = 'manage' | 'none' | 'remove';

interface FuelTypeState {
    action: FuelTypeAction,
    fuelTypes: FuelType[],
    fuelTypeSelected?: FuelType,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: FuelTypeState = {
    action: 'none',
    fuelTypes: [],
    fuelTypeSelected: undefined,
    status: 'ready'
}

const fuelTypeSlice = createSlice({
    name: 'fuelTypes',
    initialState,
    reducers: {
        selectFuelType: (state, action: PayloadAction<FuelType | undefined>) => {
            state.fuelTypeSelected = action.payload;
        },

        changeAction: (state, action: PayloadAction<FuelTypeAction>) => {
            state.action = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<FuelTypeState>) => {

        builder.addCase(getFuelTypesThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getFuelTypesThunk.fulfilled, (state, action: PayloadAction<FuelType[]>) => {
            state.fuelTypes = action.payload;
            state.status = 'ready';
        }).addCase(getFuelTypesThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(deleteFuelTypeThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteFuelTypeThunk.fulfilled, (state, action: PayloadAction<FuelType>) => {
            state.status = 'ready';
            state.fuelTypes = state.fuelTypes.filter(fuelType => !fuelType.id.isEquals(action.payload.id));
            if (state.fuelTypeSelected?.id.isEquals(action.payload.id)) {
                state.fuelTypeSelected = undefined
            }
        }).addCase(deleteFuelTypeThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findFuelTypeByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findFuelTypeByIdThunk.fulfilled, (state, action: PayloadAction<FuelType>) => {
            state.status = 'ready';
            state.fuelTypeSelected = action.payload;
        }).addCase(findFuelTypeByIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(updateFuelTypeThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateFuelTypeThunk.fulfilled, (state, action: PayloadAction<FuelType>) => {
            state.fuelTypes = state.fuelTypes.map(fuelType => fuelType.id.isEquals(action.payload.id) ? action.payload : fuelType);
            state.status = 'ready';
        }).addCase(updateFuelTypeThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(createFuelTypeThunk.fulfilled, (state, action: PayloadAction<FuelType>) => {
            state.fuelTypes.push(action.payload);
        });
    }
});

const { changeAction, selectFuelType } = fuelTypeSlice.actions;

const getAction = (state: RootState) => state.fuelType.action;
const getFuelTypes = (state: RootState) => state.fuelType.fuelTypes;
const getFuelTypeSelected = (state: RootState) => state.fuelType.fuelTypeSelected;
const getStatus = (state: RootState) => state.fuelType.status;

export {
    changeAction,
    selectFuelType,

    getAction,
    getFuelTypes,
    getFuelTypeSelected,
    getStatus
}

export default fuelTypeSlice.reducer;