import { EnvironmentBadgeId } from "../value-objects/EnvironmentBadgeId";
import { EnvironmentBadgeImageUrl } from "../value-objects/EnvironmentBadgeImageUrl";
import { EnvironmentBadgeName } from "../value-objects/EnvironmentBadgeName";

export interface EnvironmentBadgeJson {
    readonly id: string,
    readonly name: string,
    readonly imageUrl: string
}
export class EnvironmentBadge {
    constructor(
        readonly id: EnvironmentBadgeId,
        readonly name: EnvironmentBadgeName,
        readonly imageUrl: EnvironmentBadgeImageUrl
    ) { }

    public static fromJson(json: EnvironmentBadgeJson): EnvironmentBadge {
        return new EnvironmentBadge(
            new EnvironmentBadgeId(json.id),
            new EnvironmentBadgeName(json.name),
            new EnvironmentBadgeImageUrl(json.imageUrl)
        );
    }

    public static fromJsonArray(jsonArray: EnvironmentBadgeJson[]): EnvironmentBadge[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): EnvironmentBadgeJson {
        return {
            id: this.id.value,
            name: this.name.value,
            imageUrl: this.imageUrl.value
        }
    }
}