import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, patchRequest, postRequest, } from '../../../config/http';
import { MemberId } from '../../member/domain/value-objects/MemberId';
import { Vehicle } from '../domain/entities/Vehicle';
import { VehicleId } from '../domain/value_objects/VehicleId';
import { CreateVehicleDto, VehicleFiltersDto, UnlockVehicleDto, UpdateVehicleDto } from './vehicleDto';

export const createVehicleThunk = createAsyncThunk(
    'vehicle/create',
    async (createVehicle: CreateVehicleDto): Promise<Vehicle> =>
        Vehicle.fromJson(await postRequest('/vehicles', createVehicle))
);

export const deleteVehicleThunk = createAsyncThunk(
    'vehicle/delete',
    async (vehicleId: string) => Vehicle.fromJson(await deleteRequest(`/vehicles/${vehicleId}`))
);


export const lockVehicleThunk = createAsyncThunk(
    'vehicle/lock',
    async (vehicleId: string) => Vehicle.fromJson(await deleteRequest(`/vehicles/${vehicleId}`))
);


export const getVehiclesThunk = createAsyncThunk(
    'vehicle/fetch',
    async (findVehicleDto: VehicleFiltersDto): Promise<Vehicle[]> => {
        return Vehicle.fromJsonArray(await getRequest('/vehicles', JSON.parse(JSON.stringify(findVehicleDto))));
    }
);

export const countVehicleThunk = createAsyncThunk(
    'vehicle/count',
    async (findVehicleDto: VehicleFiltersDto): Promise<Number> => {
        const response = await getRequest('/vehicles/count', JSON.parse(JSON.stringify(findVehicleDto)));
        return parseInt(response.total);
    }
)

export const updateVehicleThunk = createAsyncThunk(
    'vehicle/update',
    async (updateVehicle: UpdateVehicleDto): Promise<Vehicle> => {
        //borramos los nulls y las cadenas vacías para pasar los validadores de la ruta en el back
        Object.keys(updateVehicle).forEach(k => (!updateVehicle[k] && updateVehicle[k] !== undefined) && delete updateVehicle[k]);
        return Vehicle.fromJson(await patchRequest(`/vehicles`, updateVehicle))
    }
);

export const unlockVehicleThunk = createAsyncThunk(
    'vehicle/unlock',
    async (updateVehicle: UnlockVehicleDto): Promise<Vehicle> =>
        Vehicle.fromJson(await patchRequest(`/vehicles`, updateVehicle))
);

export const findVehicleByIdThunk = createAsyncThunk(
    'vehicle/find_by_id',
    async (vehicleId: VehicleId): Promise<Vehicle> =>
        Vehicle.fromJson(await getRequest(`/vehicles/${vehicleId.value}`))
);

export const findVehiclesByMemberIdThunk = createAsyncThunk(
    'vehicle/find_by_member_id',
    async (memberId: MemberId): Promise<Vehicle[]> =>
        Vehicle.fromJsonArray(await getRequest(`/members/${memberId}/vehicles`))
)