import { getRequest, postRequest } from 'src/app/config/http';
import { MailingFilters, AttachmentEmailParam } from './mailing.dto';

export const getMembersEmailAsync = async (
  query: MailingFilters
): Promise<string> => {
  return getRequest('/members_email_by_vehicle', query);
};

export const sendCustomEmailAsync = async (
  query: MailingFilters,
  body: string,
  subject: string,
  attachments?: AttachmentEmailParam[]
): Promise<boolean> => {
  return postRequest('/emails/custom', { body, subject, attachments }, query);
};
