import { Brand, BrandJson } from "../../../brand/domain/entities/Brand";
import { VehicleModelId } from "../value_objects/VehicleModeId";
import { VehicleModelName } from "../value_objects/VehicleModelName";

export interface VehicleModelJson {
    readonly id: string,
    readonly brand: BrandJson
    readonly name: string,
}

export class VehicleModel {
    constructor(
        readonly id: VehicleModelId,
        readonly brand: Brand,
        readonly name: VehicleModelName
    ) { }

    public static fromJson(json: VehicleModelJson): VehicleModel {
        return new VehicleModel(
            new VehicleModelId(json.id),
            Brand.fromJson(json.brand),
            new VehicleModelName(json.name)
        );
    }

    public static fromJsonArray(jsonArray: VehicleModelJson[]): VehicleModel[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): VehicleModelJson {
        return {
            id: this.id.value,
            brand: this.brand.toJson(),
            name: this.name.value
        }
    }
}