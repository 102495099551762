import { FeeAnnualQuota } from "../value_objects/FeeAnnualQuota";
import { FeeEntryQuota } from "../value_objects/FeeEntryQuota";
import { FeeId } from "../value_objects/FeeId";
import { FeeName } from "../value_objects/FeeName";

export interface FeeJson {
    readonly id: string,

    readonly annualQuota: number,
    readonly entryQuota: number,
    readonly name: string
}

export class Fee {
    constructor(
        readonly id: FeeId,
        readonly annualQuota: FeeAnnualQuota,
        readonly entryQuota: FeeEntryQuota,
        readonly name: FeeName
    ) {}

    static fromJson(json: FeeJson): Fee {
        return new Fee(
            new FeeId(json.id),
            new FeeAnnualQuota(json.annualQuota),
            new FeeEntryQuota(json.entryQuota),
            new FeeName(json.name)
        );
    }

    static fromJsonArray(jsonArray: FeeJson[]): Fee[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): FeeJson {
        return {
            id: this.id.value,
            annualQuota: this.annualQuota.value,
            entryQuota: this.entryQuota.value,
            name: this.name.value
        }
    }
}