import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../config/http';
import { PriceId } from '../../../features/price/domain/value-objects/PriceId';
import { FeeId } from '../../fee/domain/value_objects/FeeId';
import { VehicleTypeId } from '../../vehicle_type/domain/value_objects/VehicleTypeId';
import { Price } from '../domain/entities/Price';
import { CreatePriceDto, UpdatePriceDto } from './priceDto';
import { patchRequest } from 'src/app/config/http';

export const createPriceThunk = createAsyncThunk(
    'price/create',
    async (createPrice: CreatePriceDto): Promise<Price> =>
        Price.fromJson(await postRequest('/prices', createPrice))
    );

    export const deletePriceThunk = createAsyncThunk(
        'table/delete',
        async (priceId: string) => Price.fromJson(await deleteRequest(`/prices/${priceId}`))
    );

    export const getPricesThunk = createAsyncThunk(
        'price/fetch',
        async (): Promise<Price[]> => Price.fromJsonArray(await getRequest('/prices'))
    );

    export const updatePriceThunk = createAsyncThunk(
        'price/update',
        async(updatePrice: UpdatePriceDto): Promise<Price> =>
            Price.fromJson(await patchRequest(`/prices`, updatePrice))
    );

    export const findPriceByIdThunk = createAsyncThunk(
        'price/find_by_id',
        async (priceId: PriceId): Promise<Price> => 
            Price.fromJson(await getRequest(`/prices/${priceId.value}`))
    );

export const findPricesByFeeIdThunk = createAsyncThunk(
    'price/find_by_fee_id',
    async (feeId: FeeId): Promise<Price[]> =>
        Price.fromJsonArray(await getRequest(`/fees/${feeId.value}/prices`))
);

export const findPriceByFeeAndVehicleTypeIdThunk = createAsyncThunk(
    'price/find_by_fee_and_vehicle',
    async (params: {feeId: FeeId, vehicleTypeId: VehicleTypeId}): Promise<Price> => Price.fromJson(await getRequest(`/fees/${params.feeId.value}/vehicle_type/${params.vehicleTypeId.value}`))
);