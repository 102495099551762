import { v4 as uuid } from 'uuid';
import { ValueObject } from './ValueObject';

export class Uuid extends ValueObject<string> {

    static random(): Uuid {
        return new Uuid(uuid());
    }

    isEquals(uuid: Uuid): boolean {
        return this.value === uuid.value;
    }
}