import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../config/http';
import { FuelTypeId } from '../../../features/fuel_type/domain/value-objects/FuelTypeId';
import { FuelType } from '../domain/entities/FuelType';
import { CreateFuelTypeDto, UpdateFuelTypeDto } from './fuelTypeDto';
import { patchRequest } from 'src/app/config/http';

export const createFuelTypeThunk = createAsyncThunk(
    'fuel_type/create',
    async (createFuelType: CreateFuelTypeDto): Promise<FuelType> =>
        FuelType.fromJson(await postRequest('/fuel_types', createFuelType))
);

export const deleteFuelTypeThunk = createAsyncThunk(
    'fuel_type/delete',
    async (fuel_typeId: string) => FuelType.fromJson(await deleteRequest(`/fuel_types/${fuel_typeId}`))
);

export const getFuelTypesThunk = createAsyncThunk(
    'fuel_type/fetch',
    async (): Promise<FuelType[]> => FuelType.fromJsonArray(await getRequest('/fuel_types'))
);

export const updateFuelTypeThunk = createAsyncThunk(
    'fuel_type/update',
    async (updateFuelType: UpdateFuelTypeDto): Promise<FuelType> =>
        FuelType.fromJson(await patchRequest(`/fuel_types/`, updateFuelType))
);

export const findFuelTypeByIdThunk = createAsyncThunk(
    'fuel_type/find_by_id',
    async (fuelTypeId: FuelTypeId): Promise<FuelType> =>
        FuelType.fromJson(await getRequest(`/fuel_types/${fuelTypeId.value}`))
);