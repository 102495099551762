import { VehicleType, VehicleTypeJson } from "src/app/features/vehicle_type/domain/entities/VehicleType";
import { Brand, BrandJson } from "../../../brand/domain/entities/Brand";
import { EnvironmentBadge, EnvironmentBadgeJson } from "../../../environment_badge/domain/entities/EnvironmentBadge";
import { FuelType, FuelTypeJson } from "../../../fuel_type/domain/entities/FuelType";
import { MemberLite, MemberLiteJson } from "../../../member/domain/entities/MemberLite";
import { VehicleModel, VehicleModelJson } from "../../../vehicle_model/domain/entities/VehicleModel";
import { VehicleBrandLegacy } from "../value_objects/VehicleBrandLegacy";
import { VehicleClassic } from "../value_objects/VehicleClassic";
import { VehicleDropAt } from "../value_objects/VehicleDropAt";
import { VehicleId } from "../value_objects/VehicleId";
import { VehicleModelLegacy } from "../value_objects/VehicleModelLegacy";
import { VehiclePermissionUrl } from "../value_objects/VehiclePermissionUrl";
import { VehicleRegNumber } from "../value_objects/VehicleRegNumber";
import { VehicleStatus } from "../value_objects/VehicleStatus";
import { VehicleYear } from "../value_objects/VehicleYear";

export interface VehicleJson {
    readonly id: string,
    readonly brand: BrandJson,
    readonly classic: boolean,
    readonly member: MemberLiteJson,
    readonly permissionUrl: string,
    readonly regNumber: string,
    readonly status: string,
    readonly vehicleType: VehicleTypeJson,

    readonly dropAt: Date,
    readonly environmentBadge: EnvironmentBadgeJson,
    readonly fuelType: FuelTypeJson,
    readonly brandLegacy?: string,
    readonly model?: VehicleModelJson,
    readonly modelLegacy?: string,
    readonly year?: string
}

export class Vehicle {
    constructor(
        readonly id: VehicleId,
        readonly classic: VehicleClassic,
        readonly member: MemberLite,
        readonly regNumber: VehicleRegNumber,
        public status: VehicleStatus,
        public vehicleType: VehicleType,

        public brand?: Brand,
        public brandLegacy?: VehicleBrandLegacy,
        public dropAt?: VehicleDropAt,
        public environmentBadge?: EnvironmentBadge,
        public fuelType?: FuelType,
        public model?: VehicleModel,
        public modelLegacy?: VehicleModelLegacy,
        readonly year?: VehicleYear,
        readonly permissionUrl?: VehiclePermissionUrl,
    ) { }

    public static fromJson(json: VehicleJson): Vehicle {
        return new Vehicle(
            new VehicleId(json.id),
            new VehicleClassic(json.classic),
            MemberLite.fromJson(json.member),
            new VehicleRegNumber(json.regNumber),
            VehicleStatus.fromString(json.status),
            VehicleType.fromJson(json.vehicleType),

            json.brand ? Brand.fromJson(json.brand) : undefined,
            json.brandLegacy ? new VehicleBrandLegacy(json.brandLegacy) : undefined,
            json.dropAt ? new VehicleDropAt(json.dropAt) : undefined,
            json.environmentBadge ? EnvironmentBadge.fromJson(json.environmentBadge) : undefined,
            json.fuelType ? FuelType.fromJson(json.fuelType) : undefined,
            json.model ? VehicleModel.fromJson(json.model) : undefined,
            json.modelLegacy ? new VehicleModelLegacy(json.modelLegacy) : undefined,
            json.year ? new VehicleYear(json.year) : undefined,
            json.permissionUrl ? new VehiclePermissionUrl(json.permissionUrl) : undefined,

        )
    }

    public static fromJsonArray(jsonArray: VehicleJson[]): Vehicle[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public static isClassic(year: number, classicYear: number): boolean {
        const currentYear = new Date().getFullYear();
        return (currentYear - year) >= classicYear;
    }

    public toJson(): VehicleJson {
        return {
            id: this.id.value,
            brand: this.brand.toJson(),
            classic: this.classic.value,
            member: this.member.toJson(),
            regNumber: this.regNumber.value,
            status: this.status.value,
            vehicleType: this.vehicleType.toJson(),

            dropAt: this.dropAt?.value,
            environmentBadge: this.environmentBadge ? this.environmentBadge.toJson() : undefined,
            fuelType: this.fuelType ? this.fuelType.toJson() : undefined,
            model: this.model ? this.model.toJson() : undefined,
            year: this.year?.value,
            permissionUrl: this.permissionUrl?.value,
        }
    }

    public isCar(): boolean {
        return this.vehicleType.name.value.toLocaleLowerCase() === 'coche';
    }
}