import { lazy } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import TopNavigationLayout from "../layout/UserLayout";
import { ProtectedRoute } from "./ProtectedRoute";
import BoxedSidebarLayout from "../layout/BoxedSidebarLayout";
import { NavigationRoutes } from "./NavigationRoutes";
import RegisterLayout from "../layout/SignUpLayout";
import { Loader } from "../components/SuspenseLoader";

const AdminBookingSystem = Loader(lazy((() => import('src/app/pages/Admin/BookingSystem'))));
const AdminVehicles = Loader(lazy(() => import('src/app/pages/Admin/Vehicles')));
const AdminMembers = Loader(lazy(() => import('src/app/pages/Admin/Members')));
const AdminPayments = Loader(lazy(() => import('src/app/pages/Admin/Payments')));
const MembersMailingPage = Loader(lazy(() => import('src/app/pages/Admin/MembersMailing')));
const AdminStatistics = Loader(lazy(() => import('src/app/pages/Admin/Statistics')));
const ConfigurationVariables = Loader(lazy(() => import('src/app/pages/Admin/ConfigurationVariables')));
const FeesPage = Loader(lazy(() => import('src/app/pages/Admin/Fees')));
const LoginPage = Loader(lazy(() => import('src/app/pages/Login')));
const OnBoardingPage = Loader(lazy(() => import('src/app/pages/OnBoarding')));
const RecoveryPasswordPage = Loader(lazy(() => import('src/app/pages/RecoverPassword')));
const RegisterPage = Loader(lazy(() => import('src/app/pages/SignUp')));
const UserBooking = Loader(lazy(() => import('src/app/pages/BookingSystem')));
const UserHome = Loader(lazy(() => import('src/app/pages/UserHome')));
const UserProfile = Loader(lazy(() => import('src/app/pages/UserProfile')));
const VehicleAndFuelTypes = Loader(lazy(() => import('src/app/pages/Admin/VehicleAndFuelTypes')));
const VehicleModels = Loader(lazy(() => import('src/app/pages/Admin/VehicleModels')));

const redirectPath = (userRole: string): string => {
    switch (userRole) {
        case 'user':
            return NavigationRoutes.UserHome;
        case 'mechanic':
            return NavigationRoutes.AdminMembers;
        default:
            return NavigationRoutes.AdminStatistics;
    }
}

const AppNavigation = () => {
    const { user } = useAuth();

    const publicRoutes = [
        <Route
            key={NavigationRoutes.Login}
            path={NavigationRoutes.Login}
            element={
                <ProtectedRoute
                    isAllowed={user === null}
                    redirectPath={user?.role === 'user' ? NavigationRoutes.UserHome : NavigationRoutes.AdminStatistics}
                >
                    <LoginPage />
                </ProtectedRoute>
            }
        />,
        <Route
            key={NavigationRoutes.RecoveryPassword}
            path={NavigationRoutes.RecoveryPassword}
            element={
                <ProtectedRoute
                    isAllowed={user === null}
                    redirectPath={user?.role === 'user' ? NavigationRoutes.UserHome : NavigationRoutes.AdminStatistics}>
                    <RecoveryPasswordPage />
                </ProtectedRoute>
            }
        />,

        <Route key={'signUpRoutes'} element={<RegisterLayout />}>
            <Route
                key={NavigationRoutes.SignUp}
                path={NavigationRoutes.SignUp}
                element={
                    <ProtectedRoute
                        isAllowed={user === null}
                        redirectPath={user?.role === 'user' ? NavigationRoutes.UserHome : NavigationRoutes.AdminStatistics}>
                        <RegisterPage />
                    </ProtectedRoute>
                }
            />
        </Route>
    ];

    const loginUserRoutes = [
        <Route key={'loginUserRoutes'} element={<TopNavigationLayout />}>
            <Route
                key={NavigationRoutes.UserHome}
                path={NavigationRoutes.UserHome}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={user !== null ? (user.status === 'pending' ? NavigationRoutes.CompleteUser : NavigationRoutes.CompleteUser) : NavigationRoutes.Login}
                    >
                        <UserHome />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.BookingMember}
                path={NavigationRoutes.BookingMember}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={user !== null ? (user.status === 'pending' ? NavigationRoutes.CompleteUser : NavigationRoutes.CompleteUser) : NavigationRoutes.Login}
                    >
                        <UserBooking />
                    </ProtectedRoute>}>
            </Route>
            <Route
                key={NavigationRoutes.Profile}
                path={NavigationRoutes.Profile}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <UserProfile />
                    </ProtectedRoute>}
            />
        </Route>
    ];

    const incompleteUserRoutes = [
        <Route
            key={'completeUserRoutes'}
            element={<TopNavigationLayout />}
        >
            <Route
                key={NavigationRoutes.CompleteUser}
                path={NavigationRoutes.CompleteUser}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'pending'}
                        redirectPath={user === null ? NavigationRoutes.Login : NavigationRoutes.UserHome}
                    >
                        <OnBoardingPage />
                    </ProtectedRoute>
                }
            />
        </Route>
    ];

    const adminRoutes = [
        <Route key="adminRoutes" element={<BoxedSidebarLayout />}>
            <Route
                key={NavigationRoutes.AdminMembers}
                path={NavigationRoutes.AdminMembers}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin' || user?.role === 'mechanic'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <AdminMembers />
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminPayments}
                path={NavigationRoutes.AdminPayments}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <AdminPayments />
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminMembersMailing}
                path={NavigationRoutes.AdminMembersMailing}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <MembersMailingPage />
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminBookingSystem}
                path={NavigationRoutes.AdminBookingSystem}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <AdminBookingSystem />
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminHome}
                path={NavigationRoutes.AdminHome}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminVehicles}
                path={NavigationRoutes.AdminVehicles}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin' || user?.role === 'mechanic'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <AdminVehicles />
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminFees}
                path={NavigationRoutes.AdminFees}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <FeesPage />
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminVehicleModels}
                path={NavigationRoutes.AdminVehicleModels}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <VehicleModels />
                    </ProtectedRoute>
                }
            />
            <Route
                key={NavigationRoutes.AdminVehicleAndFuelTypes}
                path={NavigationRoutes.AdminVehicleAndFuelTypes}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <VehicleAndFuelTypes />
                    </ProtectedRoute>
                }
            />

            <Route
                key={NavigationRoutes.AdminStatistics}
                path={NavigationRoutes.AdminStatistics}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <AdminStatistics />
                    </ProtectedRoute>
                }
            />

            <Route
                key={NavigationRoutes.AdminConfigurationVariables}
                path={NavigationRoutes.AdminConfigurationVariables}
                element={
                    <ProtectedRoute
                        isAllowed={user?.role === 'admin'}
                        redirectPath={user !== null ? NavigationRoutes.UserHome : NavigationRoutes.Login}
                    >
                        <ConfigurationVariables />
                    </ProtectedRoute>
                }
            />
        </Route>
    ];

    const unknownPath = (
        <Route path="*" element={
            user === null
                ? <Navigate to={NavigationRoutes.Login} replace />
                : (user?.role === 'user'
                    ? (user?.status === 'pending'
                        ? <Navigate to={NavigationRoutes.CompleteUser} replace />
                        : <Navigate to={NavigationRoutes.UserHome} replace />)
                    : <Navigate to={NavigationRoutes.AdminStatistics} replace />)
        } />
    );

    const homePath = (
        <Route path="/" element={
            user === null
                ? <Navigate to={NavigationRoutes.Login} replace />
                : (user?.role === 'user'
                    ? (user?.status === 'pending'
                        ? <Navigate to={NavigationRoutes.CompleteUser} replace />
                        : <Navigate to={NavigationRoutes.UserHome} replace />)
                    : <Navigate to={NavigationRoutes.AdminStatistics} replace />)
        } />
    );

    return (
        <Routes>
            {homePath}
            {publicRoutes}
            {loginUserRoutes}
            {adminRoutes}
            {incompleteUserRoutes}
            {unknownPath}
        </Routes >
    );
}

export default AppNavigation;