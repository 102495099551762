import {
  Box,
  Card,
  Container,
  darken,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import Userbox from './Userbox';
import Logo from './Logo';

const TopBarWrapper = styled(Card)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    background: ${alpha(darken(theme.colors.primary.dark, 0.2), 0.95)};
    backdrop-filter: blur(5px);
    margin: ${theme.spacing(0, 0, 5)};
    padding: ${theme.spacing(4, 0, 44)};

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin: ${theme.spacing(0, 8, 5)};
      padding: ${theme.spacing(4, 3, 44)};
    }
    display: flex;
    align-items: center;
    border-radius: 0;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    position: relative;
`
);

const TopBarImage = styled(Box)(
  () => `
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .7;
`
);

function TopBar() {
  const theme = useTheme();

  return (
    <TopBarWrapper>
      <TopBarImage
        sx={{
          opacity: 0.7,
          background: `${theme.colors.gradients.black1}`
        }}
      />
      <TopBarImage
        sx={{
          opacity: 0.5,
          background: `${theme.colors.gradients.blue5
            }`
        }}
      />
      <TopBarImage
        sx={{
          opacity: 0.15,
          backgroundImage: 'url("/static/images/placeholders/covers/car.jpg")'
        }}
      />
      <Container
        sx={{
          zIndex: 6
        }}
        maxWidth="xl"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex">
            <Logo />
          </Box>
          <Box display="flex">
            <Userbox />
          </Box>
        </Box>
      </Container>
    </TopBarWrapper>
  );
}

export default TopBar;
