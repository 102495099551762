import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../config/http';
import { BrandId } from '../../../features/brand/domain/value-objects/BrandId';
import { Brand } from '../domain/entities/Brand';
import { CreateBrandDto, UpdateBrandDto } from './brandDto';

export const createBrandThunk = createAsyncThunk(
    'brand/create',
    async (createBrand: CreateBrandDto): Promise<Brand> =>
        Brand.fromJson(await postRequest('/brands', createBrand))
    );

    export const deleteBrandThunk = createAsyncThunk(
        'table/delete',
        async (brandId: string) => Brand.fromJson(await deleteRequest(`/brands/${brandId}`))
    );

    export const getBrandsThunk = createAsyncThunk(
        'brand/fetch',
        async (): Promise<Brand[]> => Brand.fromJsonArray(await getRequest('/brands'))
    );

    export const searchBrandsThunk = createAsyncThunk(
        'brand/search',
        async (searchText: string): Promise<Brand[]> => Brand.fromJsonArray(await getRequest(`/brands?search_text=${searchText}`))
    );

    export const updateBrandThunk = createAsyncThunk(
        'brand/update',
        async(updateBrand: UpdateBrandDto): Promise<Brand> =>
            Brand.fromJson(await putRequest(`/brands/${updateBrand.id}`, updateBrand))
    );

    export const findBrandByIdThunk = createAsyncThunk(
        'brand/find_by_id',
        async (brandId: BrandId): Promise<Brand> => 
            Brand.fromJson(await getRequest(`/brands/${brandId.value}`))
    );