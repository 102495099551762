const esJSON = {
  Commons: {
    Cancel: 'Cancelar',
    Code: 'Código de Invitación',
    Email: 'Email',
    Pending: 'Pendiente',
    Status: 'Estado',
    Statuses: {
      active: 'Activo',
      inactive: 'Inactivo',
      pending: 'Pendiente'
    }
  },

  ModifyInvitations: {
    Error: 'No se han podido modificar las invitaciones del socio',
    Title: 'Modificar Invitaciones',
    Success: 'Invitaciones modificadas correctamente'
  },

  Attachment_one: 'adjunto',
  Attachment_other: 'adjuntos',

  SendInvitation: {
    Description:
      'Vas a enviar una invitación por correo electrónico. Una vez enviada no puede cancelarse y se te restará de tus invitaciones disponibles.',
    Alert:
      'Recuerda que las invitaciones solo son válidas para personas ajenas a ArcelorMittal. Si la persona que quieres invitar es productora de ArcelorMittal debe usar el formulario de registro público.',
    Error: 'No se ha podido enviar la invitación',
    Title: 'Enviar Invitación',
    Success: 'Invitación enviada correctamente'
  },

  IncreaseInvitations: 'Modificar',
  InvitedBy: 'Invitado al Autoclub por {{resource}}',
  NoInvitationsYet: 'Aún no se han enviado invitaciones',

  AvailableInvitations: '{{resource}} Invitaciones disponibles',
  NoAvailableInvitations: 'No tienes invitaciones disponibles',

  Accept: 'Estoy de acuerdo ',
  AccountStatus: 'Estado de Cuenta',
  Actions: 'Acciones',
  Add: 'Añadir {{resource}}',
  Address: 'Dirección',
  AllPayments: 'Todos Mis Pagos',
  AnnualQuota: 'Cuota anual',

  Brand: 'Marca',
  BrandOrModel: 'marca o modelo',
  Edit: 'Editar {{resource}}',

  TotalQuota: 'Total a Pagar',

  UserHomeTitle: 'Zona de Socios',

  //Bookings
  BookingsFor: 'Tus reservas',
  BookingsSubtitle: 'Comprueba tus reservas de taller y o haz una nueva',
  NewBooking: 'Nueva reserva',
  EditBooking: 'Editar una reserva',
  CreateBooking: 'Crear reserva',
  SaveChanges: 'Guardar cambios',
  Cancel: 'Cancelar',
  StartDate: 'Fecha de inicio',
  EndDate: 'Fecha de fin',

  // Emails
  CalculateEmails: 'Buscando socios...',
  Confirm: 'Confirmar',
  ConfirmAction: 'La acción require confirmación',
  Classic: 'Clásicos',
  Filters: 'Filtros y Adjuntos',
  Mailing: 'Circular',
  NoEmails: 'Ningún socio con los criterios de búsqueda introducidos',
  SendCustomEmail: {
    Error:
      'No se ha podido enviar la circular. Vuelve a intentarlo en unos minutos',
    Success: 'Circular enviada correctamente'
  },
  SendEmail: 'Enviar Circular',
  SendEmailSummary:
    'Puedes filtrar entre todos los socios y enviar un email a cada uno de ellos',
  SendingEmails: 'Enviando circular...',
  SendingEmailsTo: 'La circular se enviará a {{resource}} socios',
  Subject: 'Asunto',
  SureSendingEmail:
    '¿Estás seguro de que deseas enviar la circular? Llegará a {{resource}} miembros del club',

  //Members
  SignupStartDate: 'Alta desde...',
  SignupEndDate: 'Alta hasta...',
  MembersListEmpty: 'No se ha encontrado ningún socio con estos datos',
  MemberAdminProfileFor: 'Datos personales de ',
  MemberPersonalDetailsSubtitle: 'Datos personales del socio',
  MemberVehicles: 'Vehículos',
  NoMemberVehicles: 'El socio no tiene ningún vehículo dado de alta',
  AllMemberPayments: 'Todos los pagos',
  NewMember: 'Nuevo Socio',

  ChangeFactoryCard: 'Cambiar Tarjeta de Empleado',
  ClickHere: 'Click aquí',
  ConfigVar: 'Variable de configuración',
  ConfigVars: 'Variables de configuración',
  ConfigVarsDescription:
    'Las variables de configuración sirven para determinar diversos valores que consulta la herramienta para determinados procesos.',
  ConfirmPassword: 'Confirmar Contraseña',
  ContactInfoTitle: 'Información de Contacto',
  ContactInfoSubtitle:
    'Información de los socios con la que gerencia se pondrá en contacto para temas importantes',
  Create: {
    Label: 'Crear {{resource}}',
    Success: '{{resource}} creada correctamente'
  },

  DateOfBirth: 'Fecha de Nacimiento',
  Delete: {
    Word: 'Borrar',
    FeeDescription:
      'La tarifa sólo será eliminada siempre y cuando no exista ningún socio asignado a ella.',
    FeeTitle: '¿Estás seguro que deseas borrar la tarifa?',
    FuelTypeDescription:
      'Se eliminará el tipo de combustible. Un tipo de combustible sólo podrá ser eliminado si no existe ningún vehículo con dicho tipo de combustible asignado',
    Success: '{{resource}} eliminada correctamente',
    Title: '¿Estás seguro que deseas eliminar {{resource}}?'
  },

  EmailInstructions: 'Sigue las instrucciones del email',
  EmailRecovery:
    'Introduce el email de tu usuario para restablecer tu contraseña',

  FactoryCard: 'Tarjeta de ArcelorMittal',
  FactoryCardSubtitle: 'Gestión de la tarjeta de trabajador de ArcelorMittal',
  FactoryCardUpload: 'Subir Tarjeta de ArcelorMittal',
  FactoryCardUploading: 'Subiendo Tarjeta de ArcelorMittal...',
  FamilyMembers: 'Invitaciones al Club',
  Fee: 'Tarifa',
  FeesTitle: 'Tarifas Socio',
  FuelType: 'Tipo de Combustible',
  FuelTypesDescription:
    'El tipo de combustible será luego un requisito obligatorio en el formulario de dar de alta vehículos para los socios del club.',
  FuelTypesTitle: 'Tipos de Combustible',

  GoToLogin: 'Continúa en la página de autenticación',

  InvitedByMe: 'Invitado por mi',
  InvitedByOther: 'Te ha invitado',

  LoginError: 'Usuario o contraseña incorrectos',
  AccountInactive:
    'No puedes acceder a la plataforma ya que tu usuario no está activo.',
  LostPassword: '¿Has olvidado tu contraseña?',

  MemberTypes: 'Tipos de Socio',
  MemberTypesDescription:
    'Un tipo de socio tendrá asignado una cuota de alta, una couta anual y una cuota por cada tipo de vehículo',
  Model: 'Modelo',
  MyPayments: 'Próxima Cuota',
  MyVehicles: 'Mis Vehículos',

  Name: 'Nombre',
  New: 'Nuevo',
  Newa: 'Nueva {{resource}}',
  Newo: 'Nuevo {{resource}}',
  NoAccount: '¿No tienes cuenta aún?',
  NoOptions: 'Sin opciones',
  NotFound: 'Ningún {{resource}} encontrado',

  MemberNumber: 'Número de socio',

  Password: 'Contraseña',
  PersonalDetails: 'Datos Personales',
  PersonalDetailsSubtitle: 'Información relacionada con tus datos personales',
  Phone: 'Teléfono',
  Profile: 'Mi Perfil',
  ProfileFor: 'Perfil de ',

  RecoverPassword: 'Recuperar Contraseña',
  RecoverPassEmailSent:
    'Se ha enviado un email para que puedas restaurar tu contraseña. Revisa tu carpeta de SPAM en tu correo.',
  RequestAccount: 'Solicita tu acceso al club',
  Required: {
    Email: 'Debes introducir tu email',
    User: 'Debes introducir tu NIF',
    Password: 'Debes introducir una contraseña'
  },

  Search: 'Buscar {{resource}}...',
  Searching: 'Buscando...',
  SignIn: 'Autenticarse',
  SignOut: 'Cerrar sesión',
  SignInInstructions:
    'Rellena los campos del formulario con tus datos de acceso',

  TermsAndConditions: 'con los términos y condiciones del club',
  TrySignIn: '¿Quieres intentar acceder de nuevo?',

  Update: {
    Success: '{{resource}} actualizada correctamente'
  },
  UpdateMember: {
    Error: 'No se ha podido actualizar el socio',
    Success: 'Socio actualizado correctamente'
  },
  UploadBox: {
    BadType: 'No puedes subir ficheros del tipo seleccionado',
    DragAndDrop: 'Arrastra los adjuntos hasta aquí',
    DragNow: 'Arrastra adjuntos para empezar subirlos',
    FilesToUpload: 'Has subido',
    Title: 'Ficheros adjuntos',
    Subtitle:
      'Puedes pulsar en el recuadro de abajo o arrastrar hasta aquí los adjuntos de la circular'
  },
  User: 'Usuario',

  Validator: {
    ConfirmPassword: 'Deben de coincidir las contraseñas',
    Email: 'Debes introducir un email válido',
    MobilePhone: 'Debes añadir un número de teléfono móvil',
    Password: 'Debes introducir una contraseña válida',
    Terms:
      'Debes confirmar que estás de acuerdo con los términos y condiciones del servicio',
    User: 'Debes introducir un NIF válido'
  },

  VehicleModels: 'Marcas y Modelos',
  Vehicles: {
    resource: 'vehículo',
    Empty:
      'No tienes vehículos dados de alta. Haz clic en el botón de Añadir vehículo.',
    delete: 'Baja'
  },
  VehicleType: 'Tipo de Vehículo',
  VehicleTypesDescription:
    'Los tipos de vehículo deberán ser completados por los socios del club cuando dan de alta un vehículo. C tipo de vehículo tendrá un precio asignado en tarifa.',
  VehicleTypesTitle: 'Tipos de Vehículo'
};

export default esJSON;
