import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMembersEmailAsync, sendCustomEmailAsync } from './mailing.service';
import { MailingFilters, SendCustomEmailParams } from './mailing.dto';

export const getNumberOfEmailsFilterThunk = createAsyncThunk(
  'mailing/emails_by_vehicle',
  async (filters: MailingFilters): Promise<Number> => {
    return (await getMembersEmailAsync(filters))?.length ?? 0;
  }
);

export const sendCustomEmailThunk = createAsyncThunk(
  'mailing/send_custom',
  async (params: SendCustomEmailParams): Promise<boolean> =>
    sendCustomEmailAsync(
      params.mailingFilters,
      params.body,
      params.subject,
      params.attachments
    )
);
