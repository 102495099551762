import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../config/http';
import { FeeId } from '../domain/value_objects/FeeId';
import { Fee } from '../domain/entities/Fee';
import { CreateFeeDto, UpdateFeeDto } from './feeDto';

export const createFeeThunk = createAsyncThunk(
    'fee/create',
    async (createFee: CreateFeeDto): Promise<Fee> =>
        Fee.fromJson(await postRequest('/fees', createFee))
    );

    export const deleteFeeThunk = createAsyncThunk(
        'table/delete',
        async (feeId: string) => Fee.fromJson(await deleteRequest(`/fees/${feeId}`))
    );

    export const getFeesThunk = createAsyncThunk(
        'fee/fetch',
        async (): Promise<Fee[]> => Fee.fromJsonArray(await getRequest('/fees'))
    );

    export const updateFeeThunk = createAsyncThunk(
        'fee/update',
        async(updateFee: UpdateFeeDto): Promise<Fee> =>
            Fee.fromJson(await putRequest(`/fees`, updateFee))
    );

    export const findFeeByIdThunk = createAsyncThunk(
        'fee/find_by_id',
        async (feeId: FeeId): Promise<Fee> => 
            Fee.fromJson(await getRequest(`/fees/${feeId.value}`))
    );