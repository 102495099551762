import { Status } from 'src/app/features/commons/entities/Status';
import { MemberLiteJson, MemberLite } from './MemberLite';

export interface InvitationJson {
    readonly id: string,
    readonly code: string,
    readonly from: MemberLiteJson,
    readonly status: string,
    readonly to: string,
}

export class Invitation {
    constructor(
        readonly id: string,

        readonly code: string,
        readonly from: MemberLite,
        readonly status: Status,
        readonly to: string
    ){}

    public static fromJson(json: InvitationJson): Invitation {
        return new Invitation(
            json.id,

            json.code,
            MemberLite.fromJson(json.from),
            Status.fromString(json.status),
            json.to
        );
    }

    public static fromJsonArray(jsonArray: InvitationJson[]):Invitation[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): InvitationJson {
        return {
            id: this.id,
            code: this.code,
            from: this.from.toJson(),
            status: this.status.value,
            to: this.to
        };
    }
}