import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import bookingReducer from '../features/booking/data/bookingSlice';
import brandReducer from '../features/brand/data/brandSlice';
import configVarsReducer from '../features/config_vars/data/configVarsSlice';
import environmentBadgeReducer from '../features/environment_badge/data/environmentBadgeSlice';
import feeReducer from '../features/fee/data/feeSlice';
import fuelTypeReducer from '../features/fuel_type/data/fuelTypeSlice';
import mailingReducer from '../features/mailing/data/mailing.slice';
import memberReducer from '../features/member/data/memberSlice';
import priceReducer from "../features/price/data/priceSlice";
import statisticsReducer from '../features/statistics/data/statisticsSlice';
import vehicleReducer from "../features/vehicle/data/vehicleSlice";
import vehicleModelReducer from '../features/vehicle_model/data/vehicleModelSlice';
import vehicleTypeReducer from "../features/vehicle_type/data/vehicleTypeSlice";
import paymentReducer from "../features/payments/data/paymentSlice";
import logReducer from "../features/log/data/logSlice";

export const store = configureStore({
  reducer: {
    booking: bookingReducer,
    brand: brandReducer,
    configVars: configVarsReducer,
    environmentBadge: environmentBadgeReducer,
    fee: feeReducer,
    fuelType: fuelTypeReducer,
    log: logReducer,
    mailing: mailingReducer,
    member: memberReducer,
    payment: paymentReducer,
    price: priceReducer,
    statistics: statisticsReducer,
    vehicle: vehicleReducer,
    vehicleModel: vehicleModelReducer,
    vehicleType: vehicleTypeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
