export class MemberPaymentType {
    static readonly Annual = new MemberPaymentType('annual');

    static readonly Car = new MemberPaymentType('car');

    static readonly Entry = new MemberPaymentType('entry');

    static readonly Motorbike = new MemberPaymentType('motorbike');

    constructor(public readonly value: string) { }

    static getValues(): MemberPaymentType[] {
        return [this.Annual, this.Car, this.Entry, this.Motorbike];
    }

    static fromString(string: string): MemberPaymentType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}