export enum NavigationRoutes {
    AdminConfigurationVariables = '/configuration_variables',
    AdminBookingSystem = '/manage_bookings',
    AdminHome = '/admin',
    AdminFees = '/fees',
    AdminMembers = '/members',
    AdminMembersMailing = '/mailing',
    AdminPayments = '/payments',
    AdminStatistics = '/statistics',
    AdminVehicles = '/vehicles',
    AdminVehicleAndFuelTypes = '/vehicle_fuel_types',
    AdminVehicleModels = '/vehicle_models',
    CompleteUser = 'complete_user',
    BookingMember = '/bookings',
    Login = '/login',
    Profile = '/profile/:user_id',
    RecoveryPassword = '/recovery_password',
    SignUp = '/signup',
    UserHome = '/user',
    ShowEvent = '/events/:event_id'
}