import moment from 'moment';
import { MemberLite, MemberLiteJson } from '../../../member/domain/entities/MemberLite';
import { PaymentStatus } from '../value_objects/PaymentStatus';
import { PaymentType } from '../value_objects/PaymentType';
import { SepaPaymentMethod, SepaPaymentMethodJson } from './SepaPaymentMethod';

export interface PaymentJson {
    readonly id: string,
    readonly annualQuota: number,
    readonly createdAt: Date,
    readonly customerId: string,
    readonly entryQuota: number,
    readonly fromDate: Date,
    readonly member: MemberLiteJson,
    readonly paymentMethod: SepaPaymentMethodJson,
    readonly regulationQuota: number,
    readonly status: string,
    readonly toDate: Date,
    readonly type: string,
    readonly vehiclesQuota: number,
    readonly errorMessage?: string,
    readonly remoteId?: string
}

export class Payment {
    constructor(
        readonly id: string,
        readonly annualQuota: number,
        readonly createdAt: Date,
        readonly customerId: string,
        readonly entryQuota: number,
        readonly fromDate: Date,
        readonly member: MemberLite,
        public paymentMethod: SepaPaymentMethod,
        readonly regulationQuota: number,
        public status: PaymentStatus,
        readonly toDate: Date,
        readonly type: PaymentType,
        readonly vehiclesQuota: number,
        public errorMessage?: string,
        public remoteId?: string
    ) { }

    public static fromJson(json: PaymentJson): Payment {
        return new Payment(
            json.id,
            json.annualQuota,
            json.createdAt,
            json.customerId,
            json.entryQuota,
            json.fromDate,
            MemberLite.fromJson(json.member),
            SepaPaymentMethod.fromJson(json.paymentMethod),
            json.regulationQuota,
            PaymentStatus.fromString(json.status),
            json.toDate,
            PaymentType.fromString(json.type),
            json.vehiclesQuota,
            json.errorMessage,
            json.remoteId
        );
    }

    public static fromJsonArray(jsonArray: PaymentJson[]): Payment[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): PaymentJson {
        return {
            id: this.id,
            annualQuota: this.annualQuota,
            createdAt: this.createdAt,
            customerId: this.customerId,
            entryQuota: this.entryQuota,
            fromDate: this.fromDate,
            member: this.member.toJson(),
            paymentMethod: this.paymentMethod.toJson(),
            regulationQuota: this.regulationQuota,
            status: this.status.value,
            toDate: this.toDate,
            type: this.type.value,
            vehiclesQuota: this.vehiclesQuota,
            errorMessage: this.errorMessage,
            remoteId: this.remoteId
        };
    }

    public static calculateDays(fromDate: Date): number {
        return (moment(fromDate).endOf('year').diff(fromDate) + 1)
    }

    public static proportionalPay(fromDate: Date, totalCost: number): number {
        const days: number = this.calculateDays(fromDate);
        return (days * totalCost / 365);
    }

    public getTotal(): number {
        return this.annualQuota + this.entryQuota + this.regulationQuota + this.vehiclesQuota;
    }
}