import { MemberId } from "../value-objects/MemberId";
import { MemberName } from "../value-objects/MemberName";
import { MemberNumber } from "../value-objects/MemberNumber";
import { MemberSurname } from "../value-objects/MemberSurname";

export interface MemberLiteJson {
    readonly id: string,
    readonly name: string,
    readonly surname: string,
    readonly memberNumber: number
}

export class MemberLite {

    constructor(
        readonly id: MemberId,
        readonly name: MemberName,
        readonly surname: MemberSurname,
        readonly memberNumber: MemberNumber,
    ) { }

    static fromJson(json: MemberLiteJson): MemberLite {
        return new MemberLite(
            new MemberId(json.id),
            new MemberName(json.name),
            new MemberSurname(json.surname),
            new MemberNumber(json.memberNumber)
        );
    }

    static fromJsonArray(jsonArray: MemberLiteJson[]): MemberLite[] {
        return jsonArray.map(json => MemberLite.fromJson(json));
    }

    public toJson(): MemberLiteJson {
        return {
            id: this.id.value,
            name: this.name.value,
            surname: this.surname.value,
            memberNumber: this.memberNumber.value
        };
    }

    public getFullName(): string {
        return `${this.name.value} ${this.surname.value}`;
    }
}