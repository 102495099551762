import { ReactNode, useEffect } from 'react';

import CalendarIcon from '@mui/icons-material/EventNoteTwoTone';
import DashboardIcon from '@mui/icons-material/LineAxisTwoTone';
import FeeIcon from '@mui/icons-material/LocalAtmTwoTone';
import MembersIcon from '@mui/icons-material/BadgeTwoTone';
import EmailIcon from '@mui/icons-material/EmailTwoTone';
import PaymentsIcon from '@mui/icons-material/EuroTwoTone';
import VehicleIcon from '@mui/icons-material/DirectionsCarTwoTone';
import { NavigationRoutes } from 'src/app/navigation/NavigationRoutes';
import { useAppSelector } from 'src/app/config/hooks';
import {
  getMemberSelected,
  getPendingMembersCount
} from 'src/app/features/member/data/memberSlice';
import { countPendingMembersThunk } from 'src/app/features/member/data/memberThunk';
import { useDispatch } from 'react-redux';
import { Member } from 'src/app/features/member/domain/entities/Member';
import SettingsIcon from '@mui/icons-material/Settings';
import { MemberRole } from 'src/app/features/member/domain/value-objects/MemberRole';
import useAuth from 'src/hooks/useAuth';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems = (): MenuItems[] => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const pendingMembers: Number = useAppSelector(getPendingMembersCount);

  useEffect(() => {
    if (user?.role === MemberRole.Admin.value) {
      dispatch(countPendingMembersThunk());
    }
  }, [user]);

  if (user?.role === MemberRole.Mechanic.value) {
    return [
      {
        heading: 'Club',
        items: [
          {
            name: 'Socios',
            icon: MembersIcon,
            link: 'members',
            badge: pendingMembers > 0 ? pendingMembers.toString() : undefined
          },
          {
            name: 'Vehículos',
            icon: VehicleIcon,
            link: 'vehicles'
          }
        ]
      }
    ];
  } else {
    return [
      {
        heading: 'General',
        items: [
          {
            name: 'Estadísticas',
            icon: DashboardIcon,
            link: NavigationRoutes.AdminStatistics
          }
        ]
      },
      {
        heading: 'Club',
        items: [
          {
            name: 'Socios',
            icon: MembersIcon,
            link: 'members',
            badge: pendingMembers > 0 ? pendingMembers.toString() : undefined
          },
          {
            name: 'Vehículos',
            icon: VehicleIcon,
            link: 'vehicles'
          },
          {
            name: 'Reservas',
            icon: CalendarIcon,
            link: 'manage_bookings'
          },
          {
            name: 'Pagos',
            icon: PaymentsIcon,
            link: NavigationRoutes.AdminPayments
          },
          {
            name: 'Circulares',
            icon: EmailIcon,
            link: NavigationRoutes.AdminMembersMailing
          }
        ]
      },
      {
        heading: 'Configuración',
        items: [
          {
            name: 'Socios y Tarifas',
            icon: FeeIcon,
            link: NavigationRoutes.AdminFees
          },
          {
            name: 'Vehículos',
            icon: VehicleIcon,
            link: 'VehiclesConfig',
            items: [
              {
                name: 'Marcas y Modelos',
                link: NavigationRoutes.AdminVehicleModels
              },
              {
                name: 'Combustibles y Tipos',
                link: NavigationRoutes.AdminVehicleAndFuelTypes
              }
            ]
          },
          {
            name: 'Variables de conf.',
            icon: SettingsIcon,
            link: NavigationRoutes.AdminConfigurationVariables
          }
        ]
      }
    ];
  }
};

export default menuItems;
