import { FuelTypeId } from "../value-objects/FuelTypeId";
import { FuelTypeName } from "../value-objects/FuelTypeName";

export interface FuelTypeJson {
    readonly id: string,
    readonly name: string
}

export class FuelType {
    constructor(
        readonly id: FuelTypeId,
        readonly name: FuelTypeName
    ) { }


    public static fromJson(json: FuelTypeJson): FuelType {
        return new FuelType(
            new FuelTypeId(json.id),
            new FuelTypeName(json.name)
        );
    }

    public static fromJsonArray(jsonArray: FuelTypeJson[]): FuelType[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): FuelTypeJson {
        return {
            id: this.id.value,
            name: this.name.value
        };
    }

}