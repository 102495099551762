import { MemberLite, MemberLiteJson } from "../../../member/domain/entities/MemberLite";
import { LogEntryDate } from "../value_objects/LogEntryDate";
import { LogId } from "../value_objects/LogId";
import { LogMessage } from "../value_objects/LogMessage";

export interface LogJson {
    readonly id: string,
    readonly author: MemberLiteJson,
    readonly member: MemberLiteJson,
    readonly message: string,
    readonly date: Date,
}

export class Log {
    constructor(
        readonly id: LogId,
        readonly author: MemberLite,
        readonly member: MemberLite,
        readonly message: LogMessage,
        readonly date: LogEntryDate
    ) { }

    static fromJson(json: LogJson): Log {
        return new Log(
            new LogId(json.id),
            MemberLite.fromJson(json.author),
            MemberLite.fromJson(json.member),
            new LogMessage(json.message),
            new LogEntryDate(json.date)
        );
    }

    public static fromJsonArray(jsonArray: LogJson[]): Log[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): LogJson {
        return {
            id: this.id.value,
            author: this.author.toJson(),
            member: this.member.toJson(),
            message: this.message.value,
            date: this.date.value
        }

    }
}