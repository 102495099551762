import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { Member } from "../../member/domain/entities/Member";
import { MemberLite } from "../../member/domain/entities/MemberLite";
import { Booking } from "../domain/entities/Booking";
import { createBookingThunk, deleteBookingThunk, fetchMemberLitesThunk, findBookingByIdThunk, getBookingsByMemberIdThunk, getBookingsThunk, updateBookingThunk } from "./bookingThunk";


interface BookingActionPayload {
    action: any,
    booking?: Booking
}
interface CalendarRange {
    start: Date,
    end: Date
}

interface BookingState {
    bookingAction: 'none' | 'create' | 'edit' | 'delete',
    bookings: Booking[],
    calendarBookings: Booking[],
    bookingSelected?: Booking,
    status: 'loading' | 'ready' | 'error',
    selectedRange: CalendarRange,

    selectedMember: Member,
    members: MemberLite[]
}

export const initialState: BookingState = {
    bookingAction: 'none',
    bookings: [],
    calendarBookings: [],
    bookingSelected: undefined,
    status: 'ready',
    selectedRange: undefined,
    selectedMember: undefined,
    members: [],
}

const bookingSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        selectBooking: (state, action: PayloadAction<Booking | undefined>) => {
            state.bookingSelected = action.payload;
        },
        selectMember: (state, action: PayloadAction<Member | undefined>) => {
            state.selectedMember = action.payload;
        },
        selectRange: (state, action: PayloadAction<CalendarRange | undefined>) => {
            state.selectedRange = action.payload;
        },
        changeBookingAction: (state: Draft<BookingState>, action: PayloadAction<BookingActionPayload | undefined>) => {
            state.bookingAction = action.payload.action;
            state.bookingSelected = action.payload.booking;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<BookingState>) => {

        builder.addCase(getBookingsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getBookingsThunk.fulfilled, (state, action: PayloadAction<Booking[]>) => {
            state.calendarBookings = action.payload;
            state.status = 'ready';
        }).addCase(getBookingsThunk.rejected, (state) => {
            state.status = 'error';
        });


        builder.addCase(fetchMemberLitesThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchMemberLitesThunk.fulfilled, (state, action: PayloadAction<MemberLite[]>) => {
            state.members = action.payload;
            state.status = 'ready';
        }).addCase(fetchMemberLitesThunk.rejected, (state) => {
            state.status = 'error';
        });



        builder.addCase(deleteBookingThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteBookingThunk.fulfilled, (state, action: PayloadAction<Booking>) => {
            state.status = 'ready';
            state.bookings = state.bookings.filter(booking => booking.id.value !== action.payload.id.value);
            state.calendarBookings = state.calendarBookings.filter(booking => booking.id.value !== action.payload.id.value);
            if (state.bookingSelected?.id.value === action.payload.id.value) {
                state.bookingSelected = undefined
            }
            state.bookingAction = 'none';
        }).addCase(deleteBookingThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(getBookingsByMemberIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getBookingsByMemberIdThunk.fulfilled, (state, action: PayloadAction<Booking[]>) => {
            state.status = 'ready';
            state.bookings = action.payload;
        }).addCase(getBookingsByMemberIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(findBookingByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findBookingByIdThunk.fulfilled, (state, action: PayloadAction<Booking>) => {
            state.status = 'ready';
            state.bookingSelected = action.payload;
        }).addCase(findBookingByIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(updateBookingThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateBookingThunk.fulfilled, (state, action: PayloadAction<Booking>) => {
            state.bookings = state.bookings.map(booking => booking.id.value === action.payload.id.value ? action.payload : booking);
            state.calendarBookings = state.calendarBookings.map(booking => booking.id.value === action.payload.id.value ? action.payload : booking);
            state.status = 'ready';
        }).addCase(updateBookingThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(createBookingThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(createBookingThunk.fulfilled, (state, action: PayloadAction<Booking>) => {
            state.status = 'ready';
            state.bookings.push(action.payload)
            state.calendarBookings.push(action.payload)
            state.bookingAction = 'none';
        }).addCase(createBookingThunk.rejected, (state) => {
            state.status = 'error';
        });
    }
});

const { changeBookingAction, selectBooking, selectRange, selectMember } = bookingSlice.actions;

const getBookingAction = (state: RootState) => state.booking.bookingAction;
const getBookings = (state: RootState) => state.booking.bookings;
const getCalendarBookings = (state: RootState) => state.booking.calendarBookings;
const getBookingSelected = (state: RootState) => state.booking.bookingSelected;
const getMembers = (state: RootState) => state.booking.members;
const getMemberSelected = (state: RootState) => state.member.memberSelected;
const getStatus = (state: RootState) => state.booking.status;
const getRangeSelected = (state: RootState) => state.booking.selectedRange;


export {
    changeBookingAction,
    selectBooking,
    selectMember,
    selectRange,

    getBookingAction,
    getBookings,
    getBookingSelected,
    getMemberSelected,
    getCalendarBookings,
    getMembers,
    getRangeSelected,
    getStatus,
}

export default bookingSlice.reducer;