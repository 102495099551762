import { useAppDispatch } from "src/app/config/hooks";
import { getMemberLiteByMemberNumber } from "../../member/data/memberService";
import { CreateMemberDto } from "../../member/data/memberDto";
import { createMemberThunk } from "../../member/data/memberThunk";
import { MemberNumber } from "../../member/domain/value-objects/MemberNumber";

const useSignUp = () => {
    const dispatch = useAppDispatch();

    const onCreateMember = (createMemberDto: CreateMemberDto) =>
        dispatch(createMemberThunk(createMemberDto));

    const onGetFamilyMemberByMemberNumber = (memberNumber: string) =>
        getMemberLiteByMemberNumber(new MemberNumber(parseInt(memberNumber)))

    return {
        onCreateMember,
        onGetFamilyMemberByMemberNumber
    }
}

export default useSignUp;