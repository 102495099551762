import { Fee, FeeJson } from "../../../fee/domain/entities/Fee";
import { VehicleType, VehicleTypeJson } from "../../../vehicle_type/domain/entities/VehicleType";
import { PriceId } from "../value-objects/PriceId";
import { PriceValue } from "../value-objects/PriceValue";

export interface PriceJson {
    readonly id: string;
    readonly vehicleType: VehicleTypeJson;
    readonly fee: FeeJson;
    readonly value: number;
}

export class Price {
    constructor(
        readonly id: PriceId,
        public fee: Fee,
        public vehicleType: VehicleType,
        readonly value: PriceValue
    ) { }

    public static fromJson(json: PriceJson): Price {
        return new Price(
            new PriceId(json.id),
            Fee.fromJson(json.fee),
            VehicleType.fromJson(json.vehicleType),
            new PriceValue(json.value)
        )
    }

    public static fromJsonArray(jsonArray: PriceJson[]): Price[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): PriceJson {
        return {
            id: this.id.value,
            fee: this.fee.toJson(),
            vehicleType: this.vehicleType.toJson(),
            value: this.value.value
        }
    }
}