import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, putRequest } from 'src/app/config/http';
import { Payment } from '../domain/entities/Payment';
import { FindPaymentsFilter } from './dtos/FindPaymentsFilter';

export const retryPaymentThunk = createAsyncThunk(
    'payment/retry',
    async (paymentId: string): Promise<Payment> =>
        Payment.fromJson(await putRequest(`/payments/${paymentId}/retry`, {}))
);

export const findPaymentsThunk = createAsyncThunk(
    'payment/find',
    async (filter: FindPaymentsFilter): Promise<Payment[]> =>
        Payment.fromJsonArray(await getRequest('/payments', filter))
);

export const countPaymentThunk = createAsyncThunk(
    'payment/count',
    async (findPAymentDto: FindPaymentsFilter): Promise<Number> => {
        const response = await getRequest('/payments/count', JSON.parse(JSON.stringify(findPAymentDto)));
        return parseInt(response.total);
    }
)