import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "src/app/config/store";
import { Payment } from "../domain/entities/Payment";
import { FindPaymentsFilter } from "./dtos/FindPaymentsFilter";
import { countPaymentThunk, findPaymentsThunk, retryPaymentThunk } from "./paymentThunk";

interface PaymentState {
    filters: FindPaymentsFilter,
    paymentSelected?: Payment,
    payments: Payment[],
    status: 'loading' | 'ready' | 'error'
    total: Number
}

export const initialState: PaymentState = {
    filters: {
        type: undefined,
        query: undefined,
        year: (new Date).getFullYear(),
        status: undefined,
        limit: 10,
        offset: 0
    },
    paymentSelected: undefined,
    payments: [],
    status: 'ready',
    total: 0
}

const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        changePaymentSelected: (state: Draft<PaymentState>, action: PayloadAction<Payment | undefined>) => {
            state.paymentSelected = action.payload;
        },
        changeFilterStatus: (state: Draft<PaymentState>, action: PayloadAction<string | undefined>) => {
            state.filters.status = action.payload;
        },
        changeFilterType: (state: Draft<PaymentState>, action: PayloadAction<string | undefined>) => {
            state.filters.type = action.payload;
        },
        changeFilterYear: (state: Draft<PaymentState>, action: PayloadAction<number | undefined>) => {
            state.filters.year = action.payload;
        },
        changeFilterQuery: (state: Draft<PaymentState>, action: PayloadAction<string | undefined>) => {
            state.filters.query = action.payload;
        },
        changeFilterLimit: (state: Draft<PaymentState>, action: PayloadAction<number | undefined>) => {
            state.filters.limit = action.payload;
        },
        changeFilterOffset: (state: Draft<PaymentState>, action: PayloadAction<number | undefined>) => {
            state.filters.offset = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<PaymentState>) => {

        builder.addCase(countPaymentThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(countPaymentThunk.fulfilled, (state, action: PayloadAction<Number>) => {
            state.total = action.payload;
            state.status = 'ready';
        }).addCase(countPaymentThunk.rejected, (state) => {
            state.status = 'error';
        });


        builder.addCase(findPaymentsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findPaymentsThunk.fulfilled, (state, action: PayloadAction<Payment[]>) => {
            state.payments = action.payload;
            state.status = 'ready';
        }).addCase(findPaymentsThunk.rejected, (state) => {
            state.status = 'error';
        });


        builder.addCase(retryPaymentThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(retryPaymentThunk.fulfilled, (state, action: PayloadAction<Payment>) => {
            state.payments = state.payments.map(payment => payment.id === action.payload.id ? action.payload : payment);
            state.paymentSelected = undefined;
            state.status = 'ready';
        }).addCase(retryPaymentThunk.rejected, (state) => {
            state.status = 'error';
        });


    }
});

const {
    changePaymentSelected,
    changeFilterLimit,
    changeFilterOffset,
    changeFilterType,
    changeFilterStatus,
    changeFilterYear,
    changeFilterQuery
} = paymentSlice.actions;

const getPaymentFilters = (state: RootState) => state.payment.filters;
const getPaymentSelected = (state: RootState) => state.payment.paymentSelected;
const getPayments = (state: RootState) => state.payment.payments;
const getStatus = (state: RootState) => state.payment.status;
const getTotal = (state: RootState) => state.payment.total;

export {
    changePaymentSelected,
    changeFilterLimit,
    changeFilterOffset,
    changeFilterType,
    changeFilterStatus,
    changeFilterYear,
    changeFilterQuery,

    getPaymentFilters,
    getPaymentSelected,
    getPayments,
    getStatus,
    getTotal
}

export default paymentSlice.reducer;