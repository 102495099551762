
import { getRequest } from "src/app/config/http";
import { MemberId } from "../../member/domain/value-objects/MemberId";
import { Log } from "../domain/entities/Log";
import { createAsyncThunk } from '@reduxjs/toolkit';

export const findMemberLogsThunk = createAsyncThunk(
    'logs/find',
    async (memberId: MemberId): Promise<Log[]> =>
        Log.fromJsonArray(await getRequest('/log', { memberId: memberId.value }))
);