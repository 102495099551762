import { SepaPaymentMethodEmail } from "../value_objects/SepaPaymentMethodEmail";
import { SepaPaymentMethodId } from "../value_objects/SepaPaymentMethodId";
import { SepaPaymentMethodLast4 } from "../value_objects/SepaPaymentMethodLast4";
import { SepaPaymentMethodName } from "../value_objects/SepaPaymentMethodName";


export interface SepaPaymentMethodJson {
    id: string,
    email: string,
    name: string,
    last4: string
}

export class SepaPaymentMethod {
    constructor(
        readonly id: SepaPaymentMethodId,
        readonly email: SepaPaymentMethodEmail,
        readonly name: SepaPaymentMethodName,
        readonly last4: SepaPaymentMethodLast4,
    ) { }

    public toJson(): SepaPaymentMethodJson {
        return {
            id: this.id.value,
            email: this.email.value,
            name: this.name.value,
            last4: this.last4.value
        }
    }

    static fromJson(json:SepaPaymentMethodJson): SepaPaymentMethod {
        return new SepaPaymentMethod(
            new SepaPaymentMethodId(json.id),
            new SepaPaymentMethodEmail(json.email),
            new SepaPaymentMethodName(json.name),
            new SepaPaymentMethodLast4(json.last4)
        );
    }
}