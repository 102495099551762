import ReactDOM from 'react-dom';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import { store } from 'src/app/config/store';
// import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/app/context/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
// import i18n (needs to be bundled ;)) 
import './i18n/i18n';
import { AuthProvider } from './app/context/FirebaseAuthContext';
import "./index.css"

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
