import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "src/app/config/store";
import { EnvironmentBadge } from "../domain/entities/EnvironmentBadge";
import { getEnvironmentBadgesThunk } from "./environmentBadgeThunk";

interface EnvironmentBadgeState {
    environmentBadges: EnvironmentBadge[],
    environmentBadgeSelected?: EnvironmentBadge,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: EnvironmentBadgeState = {
    environmentBadges: [],
    environmentBadgeSelected: undefined,
    status: 'ready'
}

const environmentBadgeSlice = createSlice({
    name: 'environmentBadges',
    initialState,
    reducers: {
        selectEnvironmentBadge: (state, action: PayloadAction<EnvironmentBadge | undefined>) => {
            state.environmentBadgeSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<EnvironmentBadgeState>) => {
        builder.addCase(getEnvironmentBadgesThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getEnvironmentBadgesThunk.fulfilled, (state, action: PayloadAction<EnvironmentBadge[]>) => {
            state.environmentBadges = action.payload;
            state.status = 'ready';
        }).addCase(getEnvironmentBadgesThunk.rejected, (state) => {
            state.status = 'error';
        });
    }
});

const { selectEnvironmentBadge } = environmentBadgeSlice.actions;

const getEnvironmentBadges = (state: RootState) => state.environmentBadge.environmentBadges;
const getEnvironmentBadgeSelected = (state: RootState) => state.environmentBadge.environmentBadgeSelected;
const getStatus = (state: RootState) => state.environmentBadge.status;

export {
    selectEnvironmentBadge,

    getEnvironmentBadges,
    getEnvironmentBadgeSelected,
    getStatus
}

export default environmentBadgeSlice.reducer;