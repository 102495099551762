import { MemberLite, MemberLiteJson } from "../../../member/domain/entities/MemberLite";
import { BookingFromDate } from "../value-objects/BookingFromDate";
import { BookingId } from "../value-objects/BookingId";
import { BookingToDate } from "../value-objects/BookingToDate";
import { BookingType } from "../value-objects/BookingType";

export interface BookingJson {
    readonly id: string,
    readonly member: MemberLiteJson,
    readonly fromDate: Date,
    readonly toDate: Date,
    readonly type: string
}

export class Booking {
    constructor(
        readonly id: BookingId,
        readonly member: MemberLite,
        readonly fromDate: BookingFromDate,
        readonly toDate: BookingToDate,
        readonly type: BookingType
    ) { }

    public static fromJson(json: BookingJson): Booking {
        return new Booking(
            new BookingId(json.id),
            MemberLite.fromJson(json.member),
            new BookingFromDate(json.fromDate),
            new BookingToDate(json.toDate),
            new BookingType(json.type)
        )
    }

    public static fromJsonArray(jsonArray: BookingJson[]): Booking[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): BookingJson {
        return {
            id: this.id.value,
            member: this.member.toJson(),
            fromDate: this.fromDate.value,
            toDate: this.toDate.value,
            type: this.type.value
        }
    }
}