import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Typography,
  Container,
  Alert,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SignUpForm from '../../pages/SignUp';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function RegisterLayout() {
  return (
    <>
      <Helmet>
        <title>Crear cuenta en Autoclub Ensidesa</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="md">
            <img
              style={{ width: 720, display: 'block', margin: 'auto' }}
              alt="AutoClub Ensidesa"
              src="/static/images/logo/autoclub-large.png"
            />
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  Crear cuenta
                </Typography>
              </Box>
              <SignUpForm />
              <Box mt={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  ¿Ya tienes cuenta?
                </Typography>{' '}
                <Link component={RouterLink} to="/">
                  <b>Entra aquí</b>
                </Link>
              </Box>
            </Card>
            <br />
            <Alert severity="warning">
              Si tienes algún problema con el registro, envíanos un correo a <a href="mailto:autoclub.ensidesa@gmail.com">autoclub.ensidesa@gmail.com</a>.
            </Alert>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default RegisterLayout;
