import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { Brand } from "../domain/entities/Brand";
import { deleteBrandThunk, findBrandByIdThunk, getBrandsThunk, updateBrandThunk, searchBrandsThunk } from './brandThunk';

interface BrandState {
    brands: Brand[],
    brandSelected?: Brand,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: BrandState = {
    brands: [],
    brandSelected: undefined,
    status: 'ready'
}

const brandSlice = createSlice({
    name: 'brands',
    initialState,
    reducers: {
        resetBrands: (state) => {
            state.brands = [];
        },

        selectBrand: (state, action: PayloadAction<Brand | undefined>) => {
            state.brandSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<BrandState>) => {
        
        builder.addCase(getBrandsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getBrandsThunk.fulfilled, (state, action: PayloadAction<Brand[]>) => {
            state.brands = action.payload;
            state.status = 'ready';
        }).addCase(getBrandsThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(searchBrandsThunk.fulfilled, (state, action: PayloadAction<Brand[]>) => {
            state.brands = action.payload;
        });

        builder.addCase(deleteBrandThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteBrandThunk.fulfilled, (state, action: PayloadAction<Brand>) => {
            state.status = 'ready';
            state.brands = state.brands.filter(brand => brand.id !== action.payload.id);
            if (state.brandSelected?.id === action.payload.id) {
                state.brandSelected = undefined
            }
        }).addCase(deleteBrandThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findBrandByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findBrandByIdThunk.fulfilled, (state, action: PayloadAction<Brand>) => {
            state.status = 'ready';
            state.brandSelected = action.payload;
        }).addCase(findBrandByIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(updateBrandThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateBrandThunk.fulfilled, (state, action: PayloadAction<Brand>) => {
            state.brands = state.brands.map(brand => brand.id === action.payload.id ? action.payload : brand);
            state.status = 'ready';
        }).addCase(updateBrandThunk.rejected, (state) => {
            state.status = 'error';
        });
    }
});

const { resetBrands, selectBrand } = brandSlice.actions;

const getBrands = (state: RootState) => state.brand.brands;
const getBrandSelected = (state: RootState) => state.brand.brandSelected;
const getStatus = (state: RootState) => state.brand.status;

export {
    resetBrands,
    selectBrand,

    getBrands,
    getBrandSelected,
    getStatus
}

export default brandSlice.reducer;