import { ConfigVarsId } from "../value_objects/ConfigVarsId";
import { DaysBetweenBooking } from "../value_objects/DaysBetweenBooking";
import { DeactivatedBookingMessage } from "../value_objects/DeactivatedBookingMessage";
import { InvitationsPerMember } from "../value_objects/InvitationsPerMember.ts";
import { MaxActiveBookings } from "../value_objects/MaxActiveBookings";
import { MaxDaysPerBooking } from "../value_objects/MaxDaysPerBooking";
import { PenaltyPayment } from "../value_objects/PenaltyPayment";

export interface ConfigVarsJson {
    readonly id: string,
    readonly daysBetweenBookings: number,
    readonly invitationsPerMember: number,
    readonly maxActiveBookings: number,
    readonly maxDaysPerBooking: number,
    readonly penaltyPayment: number,

    readonly deactivatedBookingMessage?: string
}

export class ConfigVars {
    constructor(
        readonly id: ConfigVarsId,
        readonly daysBetweenBookings: DaysBetweenBooking,
        readonly invitationsPerMember: InvitationsPerMember,
        readonly maxActiveBookings: MaxActiveBookings,
        readonly maxDaysPerBooking: MaxDaysPerBooking,
        readonly penaltyPayment: PenaltyPayment,

        readonly deactivatedBookingMessage?: DeactivatedBookingMessage
    ) { }

    static fromJson(json: ConfigVarsJson): ConfigVars {
        return new ConfigVars(
            new ConfigVarsId(json.id),
            new DaysBetweenBooking(json.daysBetweenBookings),
            new InvitationsPerMember(json.invitationsPerMember),
            new MaxActiveBookings(json.maxActiveBookings),
            new MaxDaysPerBooking(json.maxDaysPerBooking),
            new PenaltyPayment(json.penaltyPayment),

            json.deactivatedBookingMessage !== undefined ? new DeactivatedBookingMessage(json.deactivatedBookingMessage) : undefined
        )
    }

    public toJson(): ConfigVarsJson {
        return {
            id: this.id.value,
            daysBetweenBookings: this.daysBetweenBookings.value,
            invitationsPerMember: this.invitationsPerMember.value,
            maxActiveBookings: this.maxActiveBookings.value,
            maxDaysPerBooking: this.maxDaysPerBooking.value,
            penaltyPayment: this.penaltyPayment.value,

            deactivatedBookingMessage: this.deactivatedBookingMessage?.value
        }
    }
}