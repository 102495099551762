import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { VehicleModel } from "../domain/entities/VehicleModel";
import { VehicleCountResponse, VehicleModelFilters } from './vehicleModelDto';
import { deleteVehicleModelThunk, findVehicleModelByBrandIdThunk, findVehicleModelByIdThunk, getVehicleModelsCountThunk, getVehicleModelsThunk, updateVehicleModelThunk } from "./vehicleModelThunk";

export type VehicleModelAction = 'delete' | 'manage' | 'none';

interface VehicleModelState {
    action: VehicleModelAction,
    filters: VehicleModelFilters,
    vehicleModels: VehicleModel[],
    vehicleModelsCount: number,
    vehicleModelSelected?: VehicleModel,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: VehicleModelState = {
    action: 'none',
    filters: {
        offset: 0,
        limit: 10,
        search_text: undefined
    },
    vehicleModels: [],
    vehicleModelsCount: 0,
    vehicleModelSelected: undefined,
    status: 'ready'
}

const vehicleModelSlice = createSlice({
    name: 'vehicleModels',
    initialState,
    reducers: {
        changeAction: (state, action: PayloadAction<VehicleModelAction>) => {
            state.action = action.payload;
        },

        changeFilter: (state, action: PayloadAction<VehicleModelFilters>) => {
            state.filters = action.payload;
        },

        clearVehicleModels: (state) => {
            state.vehicleModels = [];
        },

        selectVehicleModel: (state, action: PayloadAction<VehicleModel | undefined>) => {
            state.vehicleModelSelected = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<VehicleModelState>) => {

        builder.addCase(getVehicleModelsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getVehicleModelsThunk.fulfilled, (state, action: PayloadAction<VehicleModel[]>) => {
            state.vehicleModels = action.payload;
            state.status = 'ready';
        }).addCase(getVehicleModelsThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(getVehicleModelsCountThunk.fulfilled, (state, action: PayloadAction<VehicleCountResponse>) => {
            state.vehicleModelsCount = action.payload.count
        });

        builder.addCase(deleteVehicleModelThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteVehicleModelThunk.fulfilled, (state, action: PayloadAction<VehicleModel>) => {
            state.status = 'ready';
            state.vehicleModels = state.vehicleModels.filter(vehicleModel => !vehicleModel.id.isEquals(action.payload.id));
            if (state.vehicleModelSelected?.id.isEquals(action.payload.id)) {
                state.vehicleModelSelected = undefined
            }
        }).addCase(deleteVehicleModelThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findVehicleModelByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findVehicleModelByIdThunk.fulfilled, (state, action: PayloadAction<VehicleModel>) => {
            state.status = 'ready';
            state.vehicleModelSelected = action.payload;
        }).addCase(findVehicleModelByIdThunk.rejected, (state) => {
            state.status = 'error'
        });


        builder.addCase(findVehicleModelByBrandIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findVehicleModelByBrandIdThunk.fulfilled, (state, action: PayloadAction<VehicleModel[]>) => {
            state.vehicleModels = action.payload;
            state.status = 'ready';
        }).addCase(findVehicleModelByBrandIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(updateVehicleModelThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateVehicleModelThunk.fulfilled, (state, action: PayloadAction<VehicleModel>) => {
            state.vehicleModels = state.vehicleModels.map(vehicleModel => vehicleModel.id.isEquals(action.payload.id) ? action.payload : vehicleModel);
            state.status = 'ready';
        }).addCase(updateVehicleModelThunk.rejected, (state) => {
            state.status = 'error';
        });
    }
});

const { changeAction, changeFilter, clearVehicleModels, selectVehicleModel } = vehicleModelSlice.actions;

const getAction = (state: RootState) => state.vehicleModel.action;
const getCount = (state: RootState) => state.vehicleModel.vehicleModelsCount;
const getVehicleModelFilters = (state: RootState) => state.vehicleModel.filters;
const getVehicleModels = (state: RootState) => state.vehicleModel.vehicleModels;
const getVehicleModelSelected = (state: RootState) => state.vehicleModel.vehicleModelSelected;
const getStatus = (state: RootState) => state.vehicleModel.status;

export {
    changeAction,
    changeFilter,
    clearVehicleModels,
    selectVehicleModel,

    getAction,
    getCount,
    getVehicleModels,
    getVehicleModelFilters,
    getVehicleModelSelected,
    getStatus
}

export default vehicleModelSlice.reducer;