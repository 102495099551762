import { Fee, FeeJson } from "../../../fee/domain/entities/Fee";
import { MemberBirthday } from "../value-objects/MemberBirthday";
import { MemberEmail } from "../value-objects/MemberEmail";
import { MemberEntryDate } from "../value-objects/MemberEntryDate";
import { MemberFactoryCardUrl } from "../value-objects/MemberFactoryCardUrl";
import { MemberId } from "../value-objects/MemberId";
import { MemberIdCard } from "../value-objects/MemberIdCard";
import { MemberKeyCode } from "../value-objects/MemberKeyCode";
import { MemberKeyNumber } from "../value-objects/MemberKeyNumber";
import { MemberMobilePhone } from "../value-objects/MemberMobilePhone";
import { MemberName } from "../value-objects/MemberName";
import { MemberNotes } from "../value-objects/MemberNotes";
import { MemberNumber } from "../value-objects/MemberNumber";
import { MemberPhone } from "../value-objects/MemberPhone";
import { MemberRole } from "../value-objects/MemberRole";
import { MemberStatus } from "../value-objects/MemberStatus";
import { MemberSurname } from "../value-objects/MemberSurname";
import { MemberUpdatedAt } from "../value-objects/MemberUpdatedAt";
import { Address, AddressJson } from "./Address";
import { MemberLite, MemberLiteJson } from './MemberLite';
import { MemberLastAccessDate } from '../value-objects/MemberLastAccessDate';
import { SepaPaymentMethod, SepaPaymentMethodJson } from "./SepaPaymentMethod";
import { StripeId } from "../value-objects/StripeId";
import { MemberDropAt } from "../value-objects/MemberDropAt";

export interface MemberJson {
    readonly id: string,
    readonly address: AddressJson,
    readonly availableInvitations: number,
    readonly birthday: Date,
    readonly email: string,
    readonly entryDate: Date,
    readonly fee: FeeJson,
    readonly idCard: string,
    readonly memberNumber: number,
    readonly mobilePhone: string,
    readonly name: string,
    readonly role: string,
    readonly status: string,
    readonly surname: string,
    readonly updatedAt: Date,

    readonly dropAt?: Date;
    readonly factoryCardUrl?: string,
    readonly familyMember?: MemberLiteJson,
    readonly keyCode?: string,
    readonly keyNumber?: string,
    readonly lastAccessDate?: Date,
    readonly notes?: string,
    readonly paymaster?: MemberLiteJson,
    readonly phone?: string,
    readonly stripeId?: string,
    readonly paymentMethod?: SepaPaymentMethodJson
}

export class Member {
    constructor(
        readonly id: MemberId,
        readonly address: Address,
        readonly availableInvitations: number,
        readonly email: MemberEmail,
        readonly entryDate: MemberEntryDate,
        readonly fee: Fee,
        readonly idCard: MemberIdCard,
        readonly memberNumber: MemberNumber,
        readonly mobilePhone: MemberMobilePhone,
        readonly name: MemberName,
        readonly role: MemberRole,
        readonly status: MemberStatus,
        readonly surname: MemberSurname,
        readonly updatedAt: MemberUpdatedAt,

        readonly birthday?: MemberBirthday,
        readonly dropAt?: MemberDropAt,
        readonly factoryCardUrl?: MemberFactoryCardUrl,
        readonly familyMember?: MemberLite,
        readonly keyCode?: MemberKeyCode,
        readonly keyNumber?: MemberKeyNumber,
        readonly lastAccessDate?: MemberLastAccessDate,
        readonly notes?: MemberNotes,
        readonly paymaster?: MemberLite,
        readonly phone?: MemberPhone,
        readonly stripeId?: StripeId,
        readonly paymentMethod?: SepaPaymentMethod
    ) { }

    public static fromJson(json: MemberJson): Member {
        return new Member(
            new MemberId(json.id),
            Address.fromJson(json.address),
            json.availableInvitations,
            new MemberEmail(json.email),
            new MemberEntryDate(json.entryDate),
            Fee.fromJson(json.fee),
            new MemberIdCard(json.idCard),
            new MemberNumber(json.memberNumber),
            new MemberMobilePhone(json.mobilePhone),
            new MemberName(json.name),
            MemberRole.fromString(json.role),
            MemberStatus.fromString(json.status),
            new MemberSurname(json.surname),
            new MemberUpdatedAt(json.updatedAt),

            json.birthday ? new MemberBirthday(json.birthday) : undefined,
            json.dropAt ? new MemberDropAt(json.dropAt) : undefined,
            json.factoryCardUrl ? new MemberFactoryCardUrl(json.factoryCardUrl) : undefined,
            json.familyMember ? MemberLite.fromJson(json.familyMember) : undefined,
            json.keyCode ? new MemberKeyCode(json.keyCode) : undefined,
            json.keyNumber ? new MemberKeyNumber(json.keyNumber) : undefined,
            json.lastAccessDate ? new MemberLastAccessDate(json.lastAccessDate) : undefined,
            json.notes ? new MemberNotes(json.notes) : undefined,
            json.paymaster ? MemberLite.fromJson(json.paymaster) : undefined,
            json.phone ? new MemberPhone(json.phone) : undefined,
            json.stripeId ? new StripeId(json.stripeId) : undefined,
            json.paymentMethod ? SepaPaymentMethod.fromJson(json.paymentMethod) : undefined
        );
    }

    public static fromJsonArray(jsonArray: MemberJson[]): Member[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): MemberJson {
        return {
            id: this.id.value,
            address: this.address.toJson(),
            availableInvitations: this.availableInvitations,
            email: this.email.value,
            entryDate: this.entryDate.value,
            fee: this.fee.toJson(),
            idCard: this.idCard.value,
            memberNumber: this.memberNumber.value,
            mobilePhone: this.mobilePhone.value,
            name: this.name.value,
            role: this.role.value,
            status: this.status.value,
            surname: this.surname.value,
            updatedAt: this.updatedAt.value,

            birthday: this.birthday?.value,
            dropAt: this.dropAt?.value ?? null,
            factoryCardUrl: this.factoryCardUrl?.value,
            familyMember: this.familyMember?.toJson(),
            keyCode: this.keyCode?.value,
            keyNumber: this.keyNumber?.value,
            lastAccessDate: this.lastAccessDate?.value,
            notes: this.notes?.value,
            paymaster: this.paymaster ? this.paymaster.toJson() : undefined,
            phone: this.phone?.value,
            stripeId: this.stripeId?.value,
            paymentMethod: this.paymentMethod ? this.paymentMethod.toJson() : undefined
        }
    }

    public getFullName(): string {
        return `${this.name.value} ${this.surname.value}`;
    }

    public hasBeenInvited(): boolean {
        return this.familyMember !== null;
    }
}