import { getRequest, patchRequest } from 'src/app/config/http';
import { Member } from '../domain/entities/Member';
import { MemberLite } from '../domain/entities/MemberLite';
import { MemberNumber } from '../domain/value-objects/MemberNumber';
import { MemberFiltersDto } from './memberDto';
const getMemberByEmailAsync = async (email: string): Promise<Member> => {
    // TODO hay que hacer que el endpoint del back devuelva un único usuario cuando el query param lleva email.
    // Hay un poco de jaleo con los endpoints. Hay que hablarlo en un daily.
    return Member.fromJsonArray(await getRequest(`/members?email=${email}`))[0];
}

const countMembersAsync = async (findMembersDto: MemberFiltersDto): Promise<Number> => {
    // TODO hay que hacer que el endpoint del back devuelva un único usuario cuando el query param lleva email.
    // Hay un poco de jaleo con los endpoints. Hay que hablarlo en un daily.
    const response = await getRequest('/members/count', JSON.parse(JSON.stringify(findMembersDto)));
    return response.total;
}

interface GetMemberLastAccessDateByIdCardResponse {
    id: string,
    email: string,
    mobilePhone: string,
    lastAccessDate?: Date,
    status: string
};

const getMemberLastAccessDateByIdCardAsync = async (idCard: string, memberNumber: string): Promise<GetMemberLastAccessDateByIdCardResponse> => {
    return getRequest(`/members/${idCard}/last_access_date`, undefined, { 'member_number': memberNumber });
}

export const getMemberLiteByMemberNumber = async (numberMember: MemberNumber) =>
    MemberLite.fromJson(await getRequest(`/members/${numberMember.value}/lite`));

export const updateLastAccessDate = async (memberId: string) =>
    Member.fromJson(await patchRequest(`/members`, { id: memberId, lastAccessDate: new Date() }));

export {
    getMemberByEmailAsync,
    getMemberLastAccessDateByIdCardAsync,
    countMembersAsync
}