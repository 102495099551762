export class Status {

    static readonly Active = new Status('active');

    static readonly Inactive = new Status('inactive');

    static readonly Pending = new Status('pending');

    constructor(public readonly value: string) { }

    static getValues(): Status[] {
        return [this.Active, this.Inactive, this.Pending];
    }
    
    static fromString(string: string): Status {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}