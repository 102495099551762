import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyBH43x7NcdGSpk1GA44mhaCOrNHuzTWQvo",
    authDomain: "autoclubensidesa-7c475.firebaseapp.com",
    projectId: "autoclubensidesa-7c475",
    storageBucket: "autoclubensidesa-7c475.appspot.com",
    messagingSenderId: "1058011012307",
    appId: "1:1058011012307:web:d63daf7b7d96ac2e864826",
    measurementId: "G-BFT145QC92"
  });
}

export default firebase;
