import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, putRequest } from '../../../config/http';
import { ConfigVars } from '../domain/entities/ConfigVars';
import { UpsertConfigVarsDto } from './configVarsDto';

export const upsertConfigVarsThunk = createAsyncThunk(
    'config_var/upsert',
    async (upsertConfigVars: UpsertConfigVarsDto): Promise<ConfigVars> =>
        ConfigVars.fromJson(await putRequest('/config_vars', upsertConfigVars))
);

export const getConfigVarsThunk = createAsyncThunk(
    'config_var/fetch',
    async (): Promise<ConfigVars> => ConfigVars.fromJson(await getRequest('/config_vars'))
);