import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { VehicleType } from "../domain/entities/VehicleType";
import { deleteVehicleTypeThunk, findVehicleTypeByIdThunk, getVehicleTypesThunk, updateVehicleTypeThunk, createVehicleTypeThunk } from './vehicleTypeThunk';

export type VehicleTypeAction = 'delete' | 'manage' | 'none';

interface VehicleTypeState {
    action: VehicleTypeAction,
    vehicleTypes: VehicleType[],
    vehicleTypeSelected?: VehicleType,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: VehicleTypeState = {
    action: 'none',
    vehicleTypes: [],
    vehicleTypeSelected: undefined,
    status: 'ready'
}

const vehicleTypeSlice = createSlice({
    name: 'vehicleTypes',
    initialState,
    reducers: {
        changeAction: (state, action: PayloadAction<VehicleTypeAction>) => {
            state.action = action.payload;
        },

        selectVehicleType: (state, action: PayloadAction<VehicleType | undefined>) => {
            state.vehicleTypeSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<VehicleTypeState>) => {

        builder.addCase(getVehicleTypesThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getVehicleTypesThunk.fulfilled, (state, action: PayloadAction<VehicleType[]>) => {
            state.vehicleTypes = action.payload;
            state.status = 'ready';
        }).addCase(getVehicleTypesThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(deleteVehicleTypeThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteVehicleTypeThunk.fulfilled, (state, action: PayloadAction<VehicleType>) => {
            state.status = 'ready';
            state.vehicleTypes = state.vehicleTypes.filter(vehicleType => !vehicleType.id.isEquals(action.payload.id));
            if (state.vehicleTypeSelected?.id === action.payload.id) {
                state.vehicleTypeSelected = undefined
            }
        }).addCase(deleteVehicleTypeThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findVehicleTypeByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findVehicleTypeByIdThunk.fulfilled, (state, action: PayloadAction<VehicleType>) => {
            state.status = 'ready';
            state.vehicleTypeSelected = action.payload;
        }).addCase(findVehicleTypeByIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(updateVehicleTypeThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateVehicleTypeThunk.fulfilled, (state, action: PayloadAction<VehicleType>) => {
            state.vehicleTypes = state.vehicleTypes.map(vehicleType => vehicleType.id.isEquals(action.payload.id) ? action.payload : vehicleType);
            state.status = 'ready';
        }).addCase(updateVehicleTypeThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(createVehicleTypeThunk.fulfilled, (state, action: PayloadAction<VehicleType>) => {
            state.vehicleTypes.push(action.payload);
        });
    }
});

const { changeAction, selectVehicleType } = vehicleTypeSlice.actions;

const getAction = (state: RootState) => state.vehicleType.action;
const getVehicleTypes = (state: RootState) => state.vehicleType.vehicleTypes;
const getVehicleTypeSelected = (state: RootState) => state.vehicleType.vehicleTypeSelected;
const getStatus = (state: RootState) => state.vehicleType.status;

export {
    changeAction,
    selectVehicleType,

    getAction,
    getVehicleTypes,
    getVehicleTypeSelected,
    getStatus
}

export default vehicleTypeSlice.reducer;