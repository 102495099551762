import { VehicleTypeId } from "../value_objects/VehicleTypeId";
import { VehicleTypeName } from "../value_objects/VehicleTypeName";


export interface VehicleTypeJson {
    readonly id: string,
    readonly name: string
}

export class VehicleType {
    constructor(
        readonly id: VehicleTypeId,
        readonly name: VehicleTypeName
    ) { }

    static fromJson({ id, name }: VehicleTypeJson): VehicleType {
        return new VehicleType(
            new VehicleTypeId(id),
            new VehicleTypeName(name)
        );
    }

    static fromJsonArray(jsonArray: VehicleTypeJson[]): VehicleType[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): VehicleTypeJson {
        return {
            id: this.id.value,
            name: this.name.value
        };
    }
}