export class PaymentStatus {
    static readonly Success = new PaymentStatus('success');

    static readonly Error = new PaymentStatus('error');

    static readonly Pending = new PaymentStatus('pending');

    constructor(public readonly value: string) { }

    static getValues(): PaymentStatus[] {
        return [this.Success, this.Error, this.Pending];
    }

    static fromString(string: string): PaymentStatus {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}