import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { Price } from "../domain/entities/Price";
import {
    deletePriceThunk,
    findPriceByIdThunk,
    getPricesThunk,
    updatePriceThunk,
    findPriceByFeeAndVehicleTypeIdThunk,
    findPricesByFeeIdThunk
} from "./priceThunk";

interface PriceState {
    prices: Price[],
    pricesForFee: Price[],
    priceForFeeAndVehicleType?: Price,
    priceSelected?: Price,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: PriceState = {
    prices: [],
    pricesForFee: [],
    priceForFeeAndVehicleType: undefined,
    priceSelected: undefined,
    status: 'ready'
}

const priceSlice = createSlice({
    name: 'prices',
    initialState,
    reducers: {
        resetPricesForFee: (state) => {
            state.pricesForFee = [];
        },
        selectPrice: (state, action: PayloadAction<Price | undefined>) => {
            state.priceSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<PriceState>) => {

        builder.addCase(getPricesThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getPricesThunk.fulfilled, (state, action: PayloadAction<Price[]>) => {
            state.prices = action.payload;
            state.status = 'ready';
        }).addCase(getPricesThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(deletePriceThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deletePriceThunk.fulfilled, (state, action: PayloadAction<Price>) => {
            state.status = 'ready';
            state.prices = state.prices.filter(price => price.id !== action.payload.id);
            if (state.priceSelected?.id === action.payload.id) {
                state.priceSelected = undefined
            }
        }).addCase(deletePriceThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findPriceByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findPriceByIdThunk.fulfilled, (state, action: PayloadAction<Price>) => {
            state.status = 'ready';
            state.priceSelected = action.payload;
        }).addCase(findPriceByIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(updatePriceThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updatePriceThunk.fulfilled, (state, action: PayloadAction<Price>) => {
            state.prices = state.prices.map(price => price.id === action.payload.id ? action.payload : price);
            state.status = 'ready';
        }).addCase(updatePriceThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findPricesByFeeIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findPricesByFeeIdThunk.fulfilled, (state, action: PayloadAction<Price[]>) => {
            state.status = 'ready';
            state.pricesForFee = action.payload;
        }).addCase(findPricesByFeeIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(findPriceByFeeAndVehicleTypeIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findPriceByFeeAndVehicleTypeIdThunk.fulfilled, (state, action: PayloadAction<Price>) => {
            state.status = 'ready';
            state.priceForFeeAndVehicleType = action.payload;
        }).addCase(findPriceByFeeAndVehicleTypeIdThunk.rejected, (state) => {
            state.status = 'error'
        });
    }
});

const { resetPricesForFee, selectPrice } = priceSlice.actions;

const getPrices = (state: RootState) => state.price.prices;
const getPricesForFee = (state: RootState) => state.price.pricesForFee;
const getPriceSelected = (state: RootState) => state.price.priceSelected;
const getPriceForFeeAndVehicleType = (state: RootState) => state.price.priceForFeeAndVehicleType;
const getStatus = (state: RootState) => state.price.status;

export {
    selectPrice,
    resetPricesForFee,

    getPrices,
    getPricesForFee,
    getPriceForFeeAndVehicleType,
    getPriceSelected,
    getStatus
}

export default priceSlice.reducer;