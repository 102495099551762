import { BrandId } from "../value-objects/BrandId";
import { BrandName } from "../value-objects/BrandName";


export interface BrandJson {
    readonly id: string,
    readonly name: string
}

export class Brand {
    constructor(
        readonly id: BrandId,
        readonly name: BrandName
    ) { }

    public static fromJson(json: BrandJson): Brand {
        return new Brand(
            new BrandId(json.id),
            new BrandName(json.name)
        )
    }

    public static fromJsonArray(jsonArray: BrandJson[]): Brand[] {
        return jsonArray.map(json => this.fromJson(json));
    }

    public toJson(): BrandJson {
        return {
            id: this.id.value,
            name: this.name.value
        }
    }
}