export class BookingType {
    static readonly Mechanical = new BookingType('mechanical');

    static readonly Body = new BookingType('body');

    constructor(public readonly value: string) { }

    static getValues(): BookingType[] {
        return [
            this.Mechanical,
            this.Body
        ];
    }
    
    static fromString(string: string): BookingType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        )
    }
}