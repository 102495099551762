import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../config/http';
import { VehicleModel } from '../domain/entities/VehicleModel';
import { VehicleModelId } from '../domain/value_objects/VehicleModeId';
import { CreateVehicleModelDto, UpdateVehicleModelDto, VehicleCountResponse, VehicleModelFilters } from './vehicleModelDto';
import { patchRequest } from 'src/app/config/http';

export const createVehicleModelThunk = createAsyncThunk(
    'vehicle_model/create',
    async (createVehicleModel: CreateVehicleModelDto): Promise<VehicleModel> =>
        VehicleModel.fromJson(await postRequest('/vehicle_models', createVehicleModel))
);

export const deleteVehicleModelThunk = createAsyncThunk(
    'vehicle_model/delete',
    async (vehicle_modelId: string) => VehicleModel.fromJson(await deleteRequest(`/vehicle_models/${vehicle_modelId}`))
);

export const getVehicleModelsThunk = createAsyncThunk(
    'vehicle_model/fetch',
    async (filters: VehicleModelFilters): Promise<VehicleModel[]> => VehicleModel.fromJsonArray(await getRequest('/vehicle_models', filters))
);

export const getVehicleModelsCountThunk = createAsyncThunk(
    'vehicle_model/fetch/count',
    async (filters: VehicleModelFilters): Promise<VehicleCountResponse> => getRequest('/vehicle_models/count', filters)
);

export const updateVehicleModelThunk = createAsyncThunk(
    'vehicle_model/update',
    async (updateVehicleModel: UpdateVehicleModelDto): Promise<VehicleModel> =>
        VehicleModel.fromJson(await patchRequest(`/vehicle_models`, JSON.parse(JSON.stringify(updateVehicleModel))))
);

export const findVehicleModelByIdThunk = createAsyncThunk(
    'vehicle_model/find_by_id',
    async (vehicle_modelId: VehicleModelId): Promise<VehicleModel> =>
        VehicleModel.fromJson(await getRequest(`/vehicle_models/${vehicle_modelId.value}`))
);

export const findVehicleModelByBrandIdThunk = createAsyncThunk(
    'vehicle_model/find_by_brand_id',
    async (brandId: string): Promise<VehicleModel[]> =>
        VehicleModel.fromJsonArray(await getRequest(`/brands/${brandId}/vehicle_models`))
);