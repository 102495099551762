import { Tooltip, styled, alpha } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: 0;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        background-color: ${theme.colors.alpha.white[10]};
        border-radius: ${theme.general.borderRadiusLg};

        &:hover {
          background-color: ${alpha(theme.colors.alpha.white[30], 0.2)};
        }
`
);

interface LogoProps {
  width?: number,
  logoName?: string
}

function Logo({ width = 100, logoName = "logo" }: LogoProps) {
  return (
    <LogoWrapper to="/">
      <Tooltip
        arrow
        placement="right"
        title="AutoClub Ensidesa"
      >
        <img
          style={{ width, display: 'block', margin: 'auto', }}
          alt="AutoClub Ensidesa"
          src={`/static/images/logo/${logoName}.png`}
        />
      </Tooltip>
    </LogoWrapper>
  );
}

export default Logo;
