import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from 'src/app/config/http';
import { FiltersStatisticsDto } from './dto/filter_statistics_dto';
import { MembersStatisticsDto } from './dto/members_statistics_dto';
import { QuotaStatisticsDto } from './dto/quota_statistics_dto';
import { VehiclesStatisticsDto } from './dto/vehicle_statistics_dto';



export const getMembersStatisticsThunk = createAsyncThunk(
    'statistics/members',
    async (filter: FiltersStatisticsDto): Promise<MembersStatisticsDto> =>
        getRequest('/statistics/members', JSON.parse(JSON.stringify({
            from_date: filter.fromDate,
            to_date: filter.toDate,
            mode: filter.mode,
            previous_from_date: filter.previousFromDate,
            previous_to_date: filter.previousToDate
        })))
);

export const getVehiclesStatisticsThunk = createAsyncThunk(
    'statistics/statistics',
    async (filter: FiltersStatisticsDto): Promise<VehiclesStatisticsDto> =>
        getRequest('/statistics/vehicles', JSON.parse(JSON.stringify({
            from_date: filter.fromDate,
            to_date: filter.toDate,
            mode: filter.mode,
            previous_from_date: filter.previousFromDate,
            previous_to_date: filter.previousToDate
        })))
);

export const getQuotaStatisticsThunk = createAsyncThunk(
    'statistics/quotas',
    async (): Promise<QuotaStatisticsDto> =>
        getRequest('/statistics/quotas')
);


