import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { Log } from "../domain/entities/Log";
import { findMemberLogsThunk } from "./logThunk";

interface LogState {
    logs: Log[] | undefined,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: LogState = {
    logs: [],
    status: 'ready'
}

const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<LogState>) => {

        builder.addCase(findMemberLogsThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findMemberLogsThunk.fulfilled, (state, action: PayloadAction<Log[]>) => {
            state.logs = action.payload;
            state.status = 'ready';
        }).addCase(findMemberLogsThunk.rejected, (state) => {
            state.status = 'error';
        });


    }
});

const findLogs = (state: RootState) => state.log.logs;
const getStatus = (state: RootState) => state.log.status;

export {
    findLogs,
    getStatus
}

export default logSlice.reducer;