import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { Fee } from "../domain/entities/Fee";
import { deleteFeeThunk, findFeeByIdThunk, getFeesThunk, updateFeeThunk, createFeeThunk } from './feeThunk';

export type FeeAction = 'delete' | 'manage' | 'none';

interface FeeState {
    action: FeeAction,
    fees: Fee[],
    feeSelected?: Fee,
    status: 'loading' | 'ready' | 'error'
}

export const initialState: FeeState = {
    action: 'none',
    fees: [],
    feeSelected: undefined,
    status: 'ready'
}

const feeSlice = createSlice({
    name: 'fees',
    initialState,
    reducers: {
        changeAction: (state, action: PayloadAction<FeeAction>) => {
            state.action = action.payload;
        },

        selectFee: (state, action: PayloadAction<Fee | undefined>) => {
            state.feeSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<FeeState>) => {
        
        builder.addCase(getFeesThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getFeesThunk.fulfilled, (state, action: PayloadAction<Fee[]>) => {
            state.fees = action.payload;
            state.status = 'ready';
        }).addCase(getFeesThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(deleteFeeThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteFeeThunk.fulfilled, (state, action: PayloadAction<Fee>) => {
            state.status = 'ready';
            state.fees = state.fees.filter(fee => !fee.id.isEquals(action.payload.id));
            if (state.feeSelected?.id === action.payload.id) {
                state.feeSelected = undefined
            }
        }).addCase(deleteFeeThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findFeeByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findFeeByIdThunk.fulfilled, (state, action: PayloadAction<Fee>) => {
            state.status = 'ready';
            state.feeSelected = action.payload;
        }).addCase(findFeeByIdThunk.rejected, (state) => {
            state.status = 'error'
        });

        builder.addCase(updateFeeThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateFeeThunk.fulfilled, (state, action: PayloadAction<Fee>) => {
            state.fees = state.fees.map(fee => fee.id.isEquals(action.payload.id) ? action.payload : fee);
            if (state.feeSelected?.id.isEquals(action.payload.id)) {
                state.feeSelected = action.payload;
            }
            state.status = 'ready';
        }).addCase(updateFeeThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(createFeeThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(createFeeThunk.fulfilled, (state, action: PayloadAction<Fee>) => {
            state.fees = [...state.fees, action.payload];
            state.status = 'ready';
        });
    }
});

const { selectFee, changeAction } = feeSlice.actions;

const getAction = (state: RootState) => state.fee.action;
const getFees = (state: RootState) => state.fee.fees;
const getFeeSelected = (state: RootState) => state.fee.feeSelected;
const getStatus = (state: RootState) => state.fee.status;

export {
    selectFee,
    changeAction,

    getAction,
    getFees,
    getFeeSelected,
    getStatus
}

export default feeSlice.reducer;