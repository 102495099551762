import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/config/store';
import { MailingFilters } from './mailing.dto';
import { getNumberOfEmailsFilterThunk, sendCustomEmailThunk } from './mailing.thunk';

export type MailingStatus = 'loading' | 'ready' | 'error' | 'clear' | 'sending';

interface MailingState {
    body: string,
    mailingCount: number,
    filters: MailingFilters,
    status: MailingStatus,
    subject: string
}

export const initialState: MailingState = {
    body: '',
    mailingCount: 0,
    filters: {},
    status: 'ready',
    subject: ''
}

const mailingSlice = createSlice({
    name: 'mailing',
    initialState,
    reducers: {
        changeBody: (state, action: PayloadAction<string>) => {
            state.body = action.payload;
        },
        changeFilters: (state, action: PayloadAction<MailingFilters>) => {
            state.filters = action.payload;
        },
        changeSubject: (state, action: PayloadAction<string>) => {
            state.subject = action.payload;
        },
        changeStatus: (state, action: PayloadAction<MailingStatus>) => {
            state.status = action.payload;
        }
    },

    extraReducers: (builder: ActionReducerMapBuilder<MailingState>) => {
        builder.addCase(getNumberOfEmailsFilterThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getNumberOfEmailsFilterThunk.fulfilled, (state, action: PayloadAction<Number>) => {
            state.mailingCount = action.payload as number;
            state.status = 'ready';
        });

        builder.addCase(sendCustomEmailThunk.pending, (state) => {
            state.status = 'sending';
        }).addCase(sendCustomEmailThunk.fulfilled, (state) => {
            state.status = 'clear';
            state.subject = '';
            state.body = '';
        });
    }
});

const { changeBody, changeFilters, changeStatus, changeSubject } = mailingSlice.actions;

const getMailingBody = (state: RootState) => state.mailing.body;
const getMailingCount = (state: RootState) => state.mailing.mailingCount;
const getMailingFilters = (state: RootState) => state.mailing.filters;
const getMailingStatus = (state: RootState) => state.mailing.status;
const getMailingSubject = (state: RootState) => state.mailing.subject;

export {
    changeBody,
    changeFilters,
    changeStatus,
    changeSubject,

    getMailingBody,
    getMailingCount,
    getMailingFilters,
    getMailingStatus,
    getMailingSubject
}

export default mailingSlice.reducer;
