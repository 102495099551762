import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { FiltersStatisticsDto } from "./dto/filter_statistics_dto";
import { MembersStatisticsDto } from "./dto/members_statistics_dto";
import { QuotaStatisticsDto } from "./dto/quota_statistics_dto";
import { VehiclesStatisticsDto } from "./dto/vehicle_statistics_dto";
import { getMembersStatisticsThunk, getVehiclesStatisticsThunk, getQuotaStatisticsThunk } from "./statisticsThunk";


interface StatisticsState {
    filter: FiltersStatisticsDto
    membersStatistics: MembersStatisticsDto | undefined,
    quotaStatistics: QuotaStatisticsDto | undefined,
    statusMembers: 'loading' | 'ready' | 'error'
    statusVehicles: 'loading' | 'ready' | 'error'
    vehiclesStatistics: VehiclesStatisticsDto | undefined
}

export const initialState: StatisticsState = {
    filter: {},
    membersStatistics: undefined,
    quotaStatistics: undefined,
    statusMembers: 'loading',
    statusVehicles: 'loading',
    vehiclesStatistics: undefined
}

const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        changeFilters: (state, action: PayloadAction<FiltersStatisticsDto>) => {
            state.filter = action.payload;
        },
        resetFilters: (state, action: PayloadAction<void>) => {
            state.filter = {};
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<StatisticsState>) => {

        builder.addCase(getMembersStatisticsThunk.pending, (state) => {
            state.statusMembers = 'loading';
        }).addCase(getMembersStatisticsThunk.fulfilled, (state, action: PayloadAction<MembersStatisticsDto>) => {
            state.membersStatistics = action.payload;
            state.statusMembers = 'ready';
        }).addCase(getMembersStatisticsThunk.rejected, (state) => {
            state.statusMembers = 'error';
        });

        builder.addCase(getVehiclesStatisticsThunk.pending, (state) => {
            state.statusVehicles = 'loading';
        }).addCase(getVehiclesStatisticsThunk.fulfilled, (state, action: PayloadAction<VehiclesStatisticsDto>) => {
            state.vehiclesStatistics = action.payload;
            state.statusVehicles = 'ready';
        }).addCase(getVehiclesStatisticsThunk.rejected, (state) => {
            state.statusVehicles = 'error';
        });

        builder.addCase(getQuotaStatisticsThunk.pending, (state) => {
            state.statusVehicles = 'loading';
        }).addCase(getQuotaStatisticsThunk.fulfilled, (state, action: PayloadAction<QuotaStatisticsDto>) => {
            state.quotaStatistics = action.payload;
            state.statusVehicles = 'ready';
        }).addCase(getQuotaStatisticsThunk.rejected, (state) => {
            state.statusVehicles = 'error';
        });
    }
});

const { changeFilters, resetFilters } = statisticsSlice.actions;

const getStatusMembers = (state: RootState) => state.statistics.statusMembers;
const getStatusVehicles = (state: RootState) => state.statistics.statusVehicles;
const getMembersStatistics = (state: RootState) => state.statistics.membersStatistics;
const getVehiclessStatistics = (state: RootState) => state.statistics.vehiclesStatistics;
const getQuotaStatistics = (state: RootState) => state.statistics.quotaStatistics;


export {
    changeFilters,
    resetFilters,

    getStatusMembers,
    getStatusVehicles,
    getMembersStatistics,
    getVehiclessStatistics,
    getQuotaStatistics
}

export default statisticsSlice.reducer;