export class MemberRole {
  static readonly Admin = new MemberRole('admin');

  static readonly Mechanic = new MemberRole('mechanic');

  static readonly User = new MemberRole('user');

  constructor(public readonly value: string) {}

  static getValues(): MemberRole[] {
    return [this.Admin, this.Mechanic, this.User];
  }

  static fromString(string: string): MemberRole {
    const value = this.getValues().find(
      (enumValue) => enumValue.value === string
    );
    if (value) {
      return value;
    }

    throw new RangeError(
      `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${
        (this as any).prototype.constructor.name
      }`
    );
  }
}
