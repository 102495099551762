import { Invitation } from "../domain/entities/Invitation";
import { getRequest, postRequest, patchRequest } from 'src/app/config/http';

export const findInvitationByCodeAsync = async (code: string): Promise<Invitation> => {
    return Invitation.fromJson(await getRequest(`/invitations?code=${code}`));
}

export const findInvitationsByMemberIdAsync = async (memberId: string): Promise<Invitation[]> => {
    return Invitation.fromJsonArray(await getRequest(`/members/${memberId}/invitations`));
}

export const sendInvitationAsync = async (memberId: string, to: string): Promise<Invitation> => {
    return Invitation.fromJson(await postRequest(`/members/${memberId}/invitations`, {to}));
}

export const updateInvitationStatusAsync = async (invitationId: string, status: string): Promise<Invitation> => {
    return Invitation.fromJson(await patchRequest(`/invitations`, {id: invitationId, status}));
}