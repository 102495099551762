export class MemberStatus {
    static readonly Active = new MemberStatus('active');

    static readonly Inactive = new MemberStatus('inactive');

    static readonly Pending = new MemberStatus('pending');

    constructor(public readonly value: string) { }

    static getValues(): MemberStatus[] {
        return [this.Active, this.Inactive, this.Pending];
    }
    
    static fromString(string: string): MemberStatus {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}