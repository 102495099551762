export class PaymentType {
    static readonly Annual = new PaymentType('annual');

    static readonly Entry = new PaymentType('entry');

    static readonly Fee = new PaymentType('fee');

    constructor(public readonly value: string) { }

    static getValues(): PaymentType[] {
        return [this.Annual, this.Entry, this.Fee];
    }

    static fromString(string: string): PaymentType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}