import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../config/store";
import { Vehicle } from "../domain/entities/Vehicle";
import { VehicleFiltersDto } from "./vehicleDto";
import {
    createVehicleThunk,
    lockVehicleThunk,
    deleteVehicleThunk,
    findVehicleByIdThunk,
    findVehiclesByMemberIdThunk,
    getVehiclesThunk,
    unlockVehicleThunk,
    updateVehicleThunk,
    countVehicleThunk
} from "./vehicleThunk";

interface VehicleActionPayload {
    action: any,
    vehicle?: Vehicle
}
interface VehicleState {
    vehicleAction: 'none' | 'create' | 'edit' | 'delete' | 'lock' | 'unlock'
    vehicles: Vehicle[],
    vehicleSelected?: Vehicle,
    status: 'loading' | 'ready' | 'error',
    total: Number,
    filters: VehicleFiltersDto
}

export const initialState: VehicleState = {
    vehicleAction: 'none',
    vehicles: [],
    vehicleSelected: undefined,
    status: 'ready',
    total: 0,
    filters: {
        status: undefined,
        regNumber: undefined,
        limit: 10,
        offset: 0
    }
}

const vehicleSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        selectVehicle: (state, action: PayloadAction<Vehicle | undefined>) => {
            state.vehicleSelected = action.payload;
        },
        changeVehicleAction: (state: Draft<VehicleState>, action: PayloadAction<VehicleActionPayload | undefined>) => {
            state.vehicleAction = action.payload.action;
            state.vehicleSelected = action.payload.vehicle;
        },
        changeFilterRegNumber: (state: Draft<VehicleState>, action: PayloadAction<string | undefined>) => {
            state.filters.regNumber = action.payload;
        },
        changeFilterStatus: (state: Draft<VehicleState>, action: PayloadAction<string | undefined>) => {
            state.filters.status = action.payload;
        },
        changeFilterLimit: (state: Draft<VehicleState>, action: PayloadAction<number | undefined>) => {
            state.filters.limit = action.payload;
        },
        changeFilterOffset: (state: Draft<VehicleState>, action: PayloadAction<number | undefined>) => {
            state.filters.offset = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<VehicleState>) => {

        builder.addCase(createVehicleThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(createVehicleThunk.fulfilled, (state, action: PayloadAction<Vehicle>) => {
            state.vehicles.push(action.payload);
            state.status = 'ready';
        }).addCase(createVehicleThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(getVehiclesThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(getVehiclesThunk.fulfilled, (state, action: PayloadAction<Vehicle[]>) => {
            state.vehicles = action.payload;
            state.status = 'ready';
        }).addCase(getVehiclesThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(countVehicleThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(countVehicleThunk.fulfilled, (state, action: PayloadAction<Number>) => {
            state.total = action.payload;
            state.status = 'ready';
        }).addCase(countVehicleThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(deleteVehicleThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(deleteVehicleThunk.fulfilled, (state, action: PayloadAction<Vehicle>) => {
            state.status = 'ready';
            state.vehicles = state.vehicles.filter(vehicle => vehicle.id.value !== action.payload.id.value);
            if (state.vehicleSelected?.id.value === action.payload.id.value) {
                state.vehicleSelected = undefined
            }
            state.vehicleAction = 'none';
        }).addCase(deleteVehicleThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(lockVehicleThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(lockVehicleThunk.fulfilled, (state, action: PayloadAction<Vehicle>) => {
            state.status = 'ready';
            state.vehicles = state.vehicles.map(vehicle => vehicle.id.value === action.payload.id.value ? action.payload : vehicle);
            if (state.vehicleSelected?.id.value === action.payload.id.value) {
                state.vehicleSelected = undefined
            }
            state.vehicleAction = 'none';
        }).addCase(lockVehicleThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findVehicleByIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findVehicleByIdThunk.fulfilled, (state, action: PayloadAction<Vehicle>) => {
            state.status = 'ready';
            state.vehicleSelected = action.payload;
        }).addCase(findVehicleByIdThunk.rejected, (state) => {
            state.status = 'error'
        });


        builder.addCase(unlockVehicleThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(unlockVehicleThunk.fulfilled, (state, action: PayloadAction<Vehicle>) => {
            state.vehicles = state.vehicles.map(vehicle => vehicle.id.value === action.payload.id.value ? action.payload : vehicle);
            state.vehicleSelected = undefined;
            state.vehicleAction = 'none';
            state.status = 'ready';
        }).addCase(unlockVehicleThunk.rejected, (state) => {
            state.status = 'error';
        });


        builder.addCase(updateVehicleThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(updateVehicleThunk.fulfilled, (state, action: PayloadAction<Vehicle>) => {
            state.vehicles = state.vehicles.map(vehicle => vehicle.id.value === action.payload.id.value ? action.payload : vehicle);
            state.vehicleSelected = undefined;
            state.vehicleAction = 'none';
            state.status = 'ready';
        }).addCase(updateVehicleThunk.rejected, (state) => {
            state.status = 'error';
        });

        builder.addCase(findVehiclesByMemberIdThunk.pending, (state) => {
            state.status = 'loading';
        }).addCase(findVehiclesByMemberIdThunk.fulfilled, (state, action: PayloadAction<Vehicle[]>) => {
            state.vehicles = action.payload;
            state.status = 'ready';
        }).addCase(findVehiclesByMemberIdThunk.rejected, (state) => {
            state.status = 'error';
        });
    }
});

const {
    changeFilterLimit,
    changeFilterOffset,
    changeFilterRegNumber,
    changeFilterStatus,
    changeVehicleAction,
    selectVehicle
} = vehicleSlice.actions;

const getVehicleFilters = (state: RootState) => state.vehicle.filters;
const getVehicleAction = (state: RootState) => state.vehicle.vehicleAction;
const getVehicles = (state: RootState) => state.vehicle.vehicles;
const getVehicleSelected = (state: RootState) => state.vehicle.vehicleSelected;
const getStatus = (state: RootState) => state.vehicle.status;
const getTotal = (state: RootState) => state.vehicle.total;

export {
    changeFilterLimit,
    changeFilterOffset,
    changeFilterRegNumber,
    changeFilterStatus,
    changeVehicleAction,
    selectVehicle,

    getVehicleFilters,
    getVehicleAction,
    getVehicles,
    getVehicleSelected,
    getStatus,
    getTotal
}

export default vehicleSlice.reducer;