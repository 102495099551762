import { useRef, useState } from 'react';
import useAuth from 'src/app/hooks/useAuth';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { NavigationRoutes } from 'src/app/navigation/NavigationRoutes';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import useMemberDetail from '../../../../features/member/ui/widgets/member_detail/useMemberDetail';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { MemberRole } from 'src/app/features/member/domain/value-objects/MemberRole';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(0, 0.5)};
    height: ${theme.spacing(6)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const {
    onChangeMemberSelected,
  } = useMemberDetail();

  const { user, logout } = useAuth();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  if (user === null) {
    return <></>;
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.initials} />
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.memberNumber}
            </UserBoxDescription>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.name} src={user.initials} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.memberNumber}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          {
            user.role !== MemberRole.Mechanic.value ? <ListItem
              onClick={() => {
                handleClose();
              }}
              button
              to={NavigationRoutes.UserHome}
              component={NavLink}
            >
              <DirectionsCarIcon fontSize="small" />
              <ListItemText primary='Mis vehículos' />
            </ListItem> : <></>
          }


          {
            user?.role !== MemberRole.Mechanic.value ? <ListItem
              onClick={() => {
                handleClose();
              }}
              button
              to={NavigationRoutes.BookingMember}
              component={NavLink}
            >
              <CalendarMonthIcon fontSize="small" />
              <ListItemText primary='Mis reservas' />
            </ListItem> : <></>
          }

          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={`/profile/${user.id}`}
            component={NavLink}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          {
            user.role === 'admin' || user.role === 'mechanic'
              ?
              <>
                <ListItem
                  onClick={() => {
                    onChangeMemberSelected(null);
                    handleClose();
                  }}
                  button
                  to={NavigationRoutes.AdminStatistics}
                  component={NavLink}
                >
                  <AdminPanelSettingsIcon fontSize="small" />
                  <ListItemText primary='Administración' />
                </ListItem>
              </>
              :
              <></>
          }
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('SignOut')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
