import { MemberPaymentConcept } from "../value-objects/MemberPaymentConcept";
import { MemberPaymentType } from "../value-objects/MemberPaymentType";
import { MemberPaymentValue } from "../value-objects/MemberPaymentValue";

export interface MemberPaymentJson {
    concept: string,
    type: string,
    value: number
}

export class MemberPayment {
    constructor(
        readonly concept: MemberPaymentConcept,
        readonly type: MemberPaymentType,
        readonly value: MemberPaymentValue
    ) {}

    public toJson(): MemberPaymentJson {
        return {
            concept: this.concept.value,
            type: this.type.value,
            value: this.value.value
        }
    } 

    static fromJson(json: MemberPaymentJson): MemberPayment {
        return new MemberPayment(
            new MemberPaymentConcept(json.concept),
            MemberPaymentType.fromString(json.type),
            new MemberPaymentValue(json.value)
        );
    }

    static fromJsonArray(jsonArray: MemberPaymentJson[]): MemberPayment[] {
        return jsonArray.map(json => this.fromJson(json));
    }
}