import { useAppDispatch, useAppSelector } from "src/app/config/hooks";
import { getMemberSelected, selectMember, getAction, changeAction, MemberAction } from "src/app/features/member/data/memberSlice";
import { DeleteMemberDto, UnsubscribeMemberDto, UpdateMemberDto } from "../../../data/memberDto";
import { acceptMemberThunk, deactivateMemberThunk, deleteMemberThunk, reactivateMemberThunk, updateMemberThunk } from "../../../data/memberThunk";
import { Member } from "../../../domain/entities/Member";
import { MemberId } from "../../../domain/value-objects/MemberId";

const useMemberDetail = () => {
    const dispatch = useAppDispatch();

    const onUpdateMember = (updateMemberDto: UpdateMemberDto) => {
        return dispatch(updateMemberThunk(updateMemberDto))
    }

    const onDeleteMember = (dto: DeleteMemberDto) => {
        return dispatch(deleteMemberThunk(dto))
    }

    const onAcceptMember = (memberId: string) => {
        return dispatch(acceptMemberThunk(new MemberId(memberId)))
    }

    const onReactivateMember = (memberId: MemberId) => {
        return dispatch(reactivateMemberThunk(memberId));
    }

    const onDeactivateMember = (dto: UnsubscribeMemberDto) => {
        return dispatch(deactivateMemberThunk(dto));
    }

    const onChangeMemberSelected = (member: Member) => {
        dispatch(selectMember(member));
    }
    const onChangeMemberAction = (action: MemberAction) => {
        dispatch(changeAction(action));
    }
    return {
        memberSelected: useAppSelector(getMemberSelected),
        memberAction: useAppSelector(getAction),


        onChangeMemberSelected,
        onChangeMemberAction,
        onDeleteMember,
        onReactivateMember,
        onUpdateMember,
        onAcceptMember,
        onDeactivateMember
    }
}

export default useMemberDetail;


