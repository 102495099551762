import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
  Divider,
  FormHelperText,
  useTheme,
  Box,
  Card,
  CardHeader,
  CardMedia,
  Alert,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useAuth from 'src/app/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { uploadFile } from 'src/app/features/commons/FirebaseServices';
import useSignUp from 'src/app/features/sign_up/ui/useSignUp';
import { v1 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { findInvitationByCodeAsync, updateInvitationStatusAsync } from 'src/app/features/member/data/invitationService';
import { Invitation } from '../../features/member/domain/entities/Invitation';
import { Status } from 'src/app/features/commons/entities/Status';
import { countMembersAsync } from 'src/app/features/member/data/memberService';

function SignUpForm() {
  const theme = useTheme();
  const { createUserWithEmailAndPassword } = useAuth();
  const navigate = useNavigate();

  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingfactoryCard, setLoadingfactoryCard] = useState(false);
  const [formMode, setFormMode] = useState(undefined);
  const [factoryCardUrl, setfactoryCardUrl] = useState(undefined);
  const [factoryCardUrlError, setfactoryCardUrlError] = useState(false);
  const [invitationCode, setInvitationCode] = useState(undefined);

  const { onCreateMember } = useSignUp();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const onUploadFactoryCard = async (file: File) => {
    setLoadingfactoryCard(true);
    setfactoryCardUrl(await uploadFile(file));
    (await uploadFile(file));
    setLoadingfactoryCard(false);
  }

  const provinces = ['Alava', 'Albacete', 'Alicante', 'Almería', 'Asturias', 'Avila', 'Badajoz', 'Barcelona', 'Burgos', 'Cáceres',
    'Cádiz', 'Cantabria', 'Castellón', 'Ciudad Real', 'Córdoba', 'La Coruña', 'Cuenca', 'Gerona', 'Granada', 'Guadalajara',
    'Guipúzcoa', 'Huelva', 'Huesca', 'Islas Baleares', 'Jaén', 'León', 'Lérida', 'Lugo', 'Madrid', 'Málaga', 'Murcia', 'Navarra',
    'Orense', 'Palencia', 'Las Palmas', 'Pontevedra', 'La Rioja', 'Salamanca', 'Segovia', 'Sevilla', 'Soria', 'Tarragona',
    'Santa Cruz de Tenerife', 'Teruel', 'Toledo', 'Valencia', 'Valladolid', 'Vizcaya', 'Zamora', 'Zaragoza'];


  const memberSchema = Yup.object().shape({
    email: Yup.string().email('Introduce un email válido').required('Introduce un email válido'),
    name: Yup.string().required('Introduce un nombre válido'),
    surname: Yup.string().required('Introduce apellidos válido'),
    idCard: Yup
      .string()
      .matches(/^\d{8}[a-zA-Z]$/, 'Introduce un DNI válido')
      .required('Introduce un DNI válido'),
    street: Yup.string().required('Introduce una calle válida'),
    city: Yup.string().required('Introduce una ciudad válida'),
    province: Yup.string().required('Introduce una provincia válida'),
    postCode: Yup.string().required('Introduce un código postal válido').length(5, "El código postal debe de tener 5 cifras"),
    mobilePhone: Yup
      .string()
      .matches(/^\d{9}$/, 'Introduce un teléfono válido')
      .required('Introduce un teléfono válido'),
    password: Yup.string()
      .min(6, 'El password debe tener al menos 6 caracteres')
      .max(255)
      .required('Introduce un pasword de al menos 6 caracteres'),
    terms: Yup.boolean().oneOf(
      [true],
      'Debes aceptar los términos y condiciones'
    )
  });

  const factoryCardForm = () => {
    return (
      <div>
        <CardHeader
          sx={{ mt: 4, p: 0 }}
          avatar={
            <Avatar
              sx={{ bgcolor: theme.palette.primary.dark }}
            >1
            </Avatar>
          }
          title="Sube una foto de tu tarjeta de trabajador haciendo clic en el botón 'Subir Tarjeta de ArcelorMittal'"
        />

        <Grid item xs={12} md={6}>
          <Card sx={{ mt: 3 }}>
            <Divider />
            <CardMedia
              component="img"
              height="230"
              width="460"
              image={factoryCardUrl ? factoryCardUrl : "https://firebasestorage.googleapis.com/v0/b/autoclubensidesa-7c475.appspot.com/o/img%2Fcarnet_arcelor.png?alt=media&token=9ba30010-2881-4f74-9f76-ad1db5b489ac"}
              alt="..."
            />
            <Box m={1}>
              <>
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: 'none' }}
                  onChange={e => onUploadFactoryCard(e.target.files[0])}
                />
                <label htmlFor="select-image">
                  <Button
                    variant="outlined"
                    fullWidth
                    component="span"
                  >
                    {
                      loadingfactoryCard
                        ?
                        t('FactoryCardUploading')
                        :
                        t('FactoryCardUpload')
                    }
                  </Button>
                </label>
              </>
            </Box>
          </Card>
          {factoryCardUrlError && <FormHelperText sx={{ color: 'red' }}>Añade una foto de la tarjeta del trabajador</FormHelperText>}
        </Grid>
      </div>
    );
  }


  return (
    <>
      <Formik
        initialValues={{
          email: '',
          name: '',
          surname: '',
          idCard: '',
          street: '',
          city: '',
          province: '',
          postCode: '',
          password: '',
          mobilePhone: '',

          invitationCode: '',
          terms: false,
          submit: null
        }}
        validationSchema={memberSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // Inicializamos variables
            let invitation: Invitation;
            setfactoryCardUrlError(false);
            // Si estamos en modo 'worker' debemos validar que tenemos tarjeta
            if (formMode === 'worker' && factoryCardUrl === undefined) {
              setfactoryCardUrlError(true);
              return;
            }
            setLoading(true);
            // Si estamos en modo 'familyMember' debemos validar que
            // tenemos numero de socio del familiar
            if (formMode === 'familyMember' && (invitationCode === undefined || invitationCode === '')) {
              // Estamos en familyMember pero no ha introducido en el formulario
              // el número de socio del familiar
              setErrors({ invitationCode: 'Introduce un código de activación' });
              setLoading(false);
              return;
            } else if (formMode === 'familyMember') {
              // Estamos en modo 'familyMember' y tenemos número de socio
              // del familiar. Lanzamos la petición al back
              // para recuperar el ID del número de socio familiar
              try {
                invitation = await findInvitationByCodeAsync(invitationCode);
                if (invitation.status !== Status.Pending) {
                  invitation = undefined;
                  throw Error('La invitación ya ha sido canjeada');
                }
              } catch (error) {
                // Despues de la petición, no hemos encontrado el socio
                // con el número introducido.
                setLoading(false);
                setErrors({ invitationCode: 'El código de activación no existe o ya ha sido utilizado' });
                return;
              }
            }

            // Tenemos que validar que el email introducido no esté ya en base de datos
            const totalMemberByEmail: Number = await countMembersAsync({ searchText: values.email });
            if (totalMemberByEmail > 0) {
              setErrors({ email: 'El email introducido ya existe' });
              setLoading(false);
              return;
            }
            // Tenemos que validar que la idCard introducido no esté ya en base de datos
            const totalMemberByIdCard: Number = await countMembersAsync({ searchText: values.idCard });
            if (totalMemberByIdCard > 0) {
              setErrors({ idCard: 'El DNI introducido ya existe' });
              setLoading(false);
              return;
            }

            //Creamos el usuario en base de datos
            onCreateMember({
              id: v1(),
              ...values,
              factoryCardUrl: formMode === 'worker' ? factoryCardUrl : undefined,
              address: {
                street: values.street,
                city: values.city,
                postCode: values.postCode,
                province: values.province
              },
              familyMemberId: formMode === 'familyMember' ? invitation.from.id.value : undefined
            }).then(async () => {
              // Pasadas las validaciones y la creación en BD del usuario
              // Pasamos a registrar el usuario
              await createUserWithEmailAndPassword(values.email, values.password);
              if (invitation) {
                await updateInvitationStatusAsync(invitation.id, 'active');
              }

              setLoading(false);
              navigate('/');
            }).catch((error) => {
              setLoading(false);
              console.log(error);
            });


            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (err.code === 400) {
              setErrors({ email: 'El email ya está en uso' });
            }
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          touched,
          getFieldProps,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 3,
                mt: 3
              }}
            >
              ¿Eres trabajador de ArcelorMittal? Haz clic en una de las siguientes opciones
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={() => setFormMode('worker')}
                  variant="outlined"
                  fullWidth
                  component="span"
                  color="primary"
                  sx={{
                    padding: '4.5% 0',
                    backgroundColor: (formMode === 'worker') ? theme.palette.primary.dark : 'white',
                    color: (formMode === 'worker') ? 'white' : theme.palette.primary.main
                  }}
                >
                  Si, soy trabajador de ArcelorMittal
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={() => setFormMode('familyMember')}
                  variant="outlined"
                  fullWidth
                  color="primary"
                  component="span"
                  sx={{
                    padding: '4.5% 0',
                    backgroundColor: (formMode === 'familyMember') ? theme.palette.primary.dark : 'white',
                    color: (formMode === 'familyMember') ? 'white' : theme.palette.primary.main
                  }}
                >
                  No, pero tengo una invitación.
                </Button>
              </Grid>
            </Grid>

            {
              formMode === 'worker'
                ? factoryCardForm()
                : <></>
            }

            {
              formMode === 'familyMember'
                ?
                (
                  <div>
                    <CardHeader
                      sx={{ mt: 4, mb: 3, p: 0 }}
                      avatar={
                        <Avatar
                          sx={{ bgcolor: theme.palette.primary.dark }}
                        >1
                        </Avatar>
                      }
                      title="Introduce el código de invitación"
                    />

                    <Grid container spacing={3} >
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            color="secondary"
                            label="Código de Invitación"
                            variant="outlined"
                            name="invitationCode"
                            key="invitationCode"
                            onChange={(event) => {
                              setInvitationCode(event.target.value);
                            }}
                            inputProps={{ maxLength: 9 }}
                            helperText={touched.invitationCode && errors.invitationCode}
                            error={touched.invitationCode && errors.invitationCode !== undefined}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                )
                :
                <></>
            }

            {
              formMode === 'worker' || formMode === 'familyMember'
                ?
                (<div>
                  <CardHeader
                    sx={{ mt: 6, mb: 3, p: 0 }}
                    avatar={
                      <Avatar
                        sx={{ bgcolor: theme.palette.primary.dark }}
                      >2
                      </Avatar>
                    }
                    title="Rellena todos los campos del siguiente formulario con tu información personal"
                  />

                  <Grid container spacing={3} >
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          key="name"
                          fullWidth
                          color="secondary"
                          label="Nombre"
                          variant="outlined"
                          name="name"
                          {...getFieldProps('name')}
                          helperText={touched.name && errors.name}
                          error={touched.name && errors.name !== undefined}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          key="surname"
                          fullWidth
                          color="secondary"
                          label="Apellidos"
                          variant="outlined"
                          name="surname"
                          {...getFieldProps('surname')}
                          helperText={touched.surname && errors.surname}
                          error={touched.surname && errors.surname !== undefined}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          key='password'
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label='Contraseña'
                          // placeholder='Contraseña al menos 6 caracteres'
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          value={values.password}
                          variant="outlined"
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Ver / Ocultar contraseña"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword
                                    ?
                                    <VisibilityOffIcon />
                                    :
                                    <VisibilityIcon />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </FormControl>
                    </Grid>


                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          key='email'
                          label='Email'
                          name="email"
                          type="email"
                          value={values.email}
                          variant="outlined"
                          helperText={touched.email && errors.email}
                          error={touched.email && errors.email !== undefined}
                          {...getFieldProps('email')}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          key="mobilePhone"
                          fullWidth
                          color="secondary"
                          label="Teléfono"
                          variant="outlined"
                          name="mobilePhone"
                          inputProps={{ maxLength: 9 }}
                          {...getFieldProps('mobilePhone')}
                          helperText={touched.mobilePhone && errors.mobilePhone}
                          error={touched.mobilePhone && errors.mobilePhone !== undefined}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          key="idCard"
                          fullWidth
                          color="secondary"
                          label="DNI"
                          variant="outlined"
                          name="idCard"
                          {...getFieldProps('idCard')}
                          inputProps={{ maxLength: 9 }}
                          helperText={touched.idCard && errors.idCard}
                          error={touched.idCard && errors.idCard !== undefined}
                        />
                      </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          key="street"
                          fullWidth
                          color="secondary"
                          label="Calle"
                          variant="outlined"
                          placeholder="Introduce calle, número, planta y puerta"
                          name="street"
                          {...getFieldProps('street')}
                          helperText={touched.street && errors.street}
                          error={touched.street && errors.street !== undefined}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          key="postCode"
                          fullWidth
                          color="secondary"
                          label="Código Postal"
                          variant="outlined"
                          name="postCode"
                          {...getFieldProps('postCode')}
                          inputProps={{ maxLength: 5 }}
                          helperText={touched.postCode && errors.postCode}
                          error={touched.postCode && errors.postCode !== undefined}
                        />
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          key="city"
                          fullWidth
                          color="secondary"
                          label="Ciudad"
                          variant="outlined"
                          name="city"
                          {...getFieldProps('city')}
                          helperText={touched.city && errors.city}
                          error={touched.city && errors.city !== undefined}
                        />
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="province-label">Provincia</InputLabel>
                        <Select
                          key="province"
                          label="Provincia"
                          labelId='province-label'
                          id="province"
                          name="province"
                          onChange={handleChange}
                          value={values.province}
                          error={touched.province && errors.province !== undefined}
                        >
                          {
                            provinces.map(province =>
                              <MenuItem key={province} value={province}>{province}</MenuItem>
                            )
                          }
                        </Select>
                        {touched.province && errors.province !== undefined && <FormHelperText sx={{ color: 'red' }}>Selecciona una provincia</FormHelperText>}
                      </FormControl>

                    </Grid>
                  </Grid>


                  <FormControlLabel sx={{ marginTop: '10px' }}
                    control={
                      <Checkbox
                        checked={values.terms}
                        name="terms"
                        color="primary"
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Acepto los {' '}
                        <Link
                          target='_blank'
                          component="a"
                          href="https://firebasestorage.googleapis.com/v0/b/autoclubensidesa-7c475.appspot.com/o/Legal%2Fterminos_condiciones.pdf?alt=media&token=ab4752b3-e21f-4359-a83e-3c3f6b5b14aa">
                          términos y condiciones
                        </Link>
                        .
                      </Typography>
                    }
                  />
                  {touched.terms && errors.terms !== undefined && <FormHelperText sx={{ color: 'red' }}>Debes aceptar los términos y condiciones</FormHelperText>}
                </div>)
                :
                <></>
            }

            {
              formMode === 'external'
                ?
                (
                  <Alert severity="warning" sx={{ marginTop: 4 }}>
                    Actualmente no está permitido hacerse socio del AutoClub Ensidesa si no tienes relación directa con ArcelorMittal.
                    Envíanos un correo <a href="mailto:autoclub.ensidesa@gmail.com">autoclub.ensidesa@gmail.com</a> con tus datos y estudiaremos tu caso en particular.
                  </Alert>
                )
                :
                <></>
            }

            {
              (formMode === 'worker' || formMode === 'familyMember')
                ?
                (<Button
                  sx={{
                    mt: 3
                  }}
                  color="primary"
                  startIcon={loading ? <CircularProgress size="1rem" color="info" /> : null}
                  disabled={loading}
                  size="large"
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {
                    loading
                      ?
                      'Creando cuenta, espera por favor...'
                      :
                      'Crear cuenta'
                  }
                </Button>)
                :
                <></>
            }
          </form>
        )}
      </Formik>
    </>
  );
}

export default SignUpForm;
