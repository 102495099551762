type Primitives = string | number | boolean | Date;

export abstract class ValueObject<T extends Primitives> {
    readonly value: T;

    constructor(value: T) {
        this.value = value;

    }

    private ensureValueIsDefined(value: T): void {
        if (value === null || value === undefined) {
            throw new Error('Value must be defined');
        }
    }

    public equals(other: ValueObject<T>): boolean {
        return other.constructor.name === this.constructor.name && other.value === this.value;
    }

    public toString(): string {
        return this.value.toString();
    }
}