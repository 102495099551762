import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../config/http';
import { VehicleType } from '../domain/entities/VehicleType';
import { VehicleTypeId } from '../domain/value_objects/VehicleTypeId';
import { CreateVehicleTypeDto, UpdateVehicleTypeDto } from './vehicleTypeDto';
import { patchRequest } from 'src/app/config/http';

export const createVehicleTypeThunk = createAsyncThunk(
    'vehicle_type/create',
    async (createVehicleType: CreateVehicleTypeDto): Promise<VehicleType> =>
        VehicleType.fromJson(await putRequest('/vehicle_types', createVehicleType))
);

export const deleteVehicleTypeThunk = createAsyncThunk(
    'vehicle_type/delete',
    async (vehicle_typeId: string) => VehicleType.fromJson(await deleteRequest(`/vehicle_types/${vehicle_typeId}`))
);

export const getVehicleTypesThunk = createAsyncThunk(
    'vehicle_type/fetch',
    async (): Promise<VehicleType[]> => VehicleType.fromJsonArray(await getRequest('/vehicle_types'))
);

export const updateVehicleTypeThunk = createAsyncThunk(
    'vehicle_type/update',
    async (updateVehicleType: UpdateVehicleTypeDto): Promise<VehicleType> =>
        VehicleType.fromJson(await putRequest(`/vehicle_types`, updateVehicleType))
);

export const findVehicleTypeByIdThunk = createAsyncThunk(
    'vehicle_type/find_by_id',
    async (vehicle_typeId: VehicleTypeId): Promise<VehicleType> =>
        VehicleType.fromJson(await getRequest(`/vehicle_types/${vehicle_typeId.value}`))
);