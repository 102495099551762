import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, putRequest, patchRequest } from '../../../config/http';
import { BookingId } from '../../../features/booking/domain/value-objects/BookingId';
import { MemberLite } from '../../member/domain/entities/MemberLite';
import { Booking } from '../domain/entities/Booking';
import { CreateBookingDto, GetBookingsParams, UpdateBookingDto } from './bookingDto';


export const createBookingThunk = createAsyncThunk(
    'booking/create',
    async (createBooking: CreateBookingDto): Promise<Booking> =>
        Booking.fromJson(await postRequest('/bookings', createBooking))
);

export const deleteBookingThunk = createAsyncThunk(
    'table/delete',
    async (bookingId: string) => Booking.fromJson(await deleteRequest(`/bookings/${bookingId}`))
);

export const getBookingsByMemberIdThunk = createAsyncThunk(
    'booking/get_by_member_id',
    async (memberId: string): Promise<Booking[]> => Booking.fromJsonArray(await getRequest(`/members/${memberId}/bookings`))
);

export const getBookingsThunk = createAsyncThunk(
    'booking/fetch',
    async (params?: GetBookingsParams): Promise<Booking[]> => Booking.fromJsonArray(await getRequest('/bookings', params))
);

export const updateBookingThunk = createAsyncThunk(
    'booking/update',
    async (updateBooking: UpdateBookingDto): Promise<Booking> =>
        Booking.fromJson(await patchRequest(`/bookings/`, updateBooking))
);

export const findBookingByIdThunk = createAsyncThunk(
    'booking/find_by_id',
    async (bookingId: BookingId): Promise<Booking> =>
        Booking.fromJson(await getRequest(`/bookings/${bookingId.value}`))
);

export const fetchMemberLitesThunk = createAsyncThunk(
    'booking/fetch_member_lites',
    async (): Promise<MemberLite[]> => MemberLite.fromJsonArray(await getRequest('/member_lites'))
)