import { AddressCity } from "../value-objects/AddressCity";
import { AddressPostCode } from "../value-objects/AddressPostCode";
import { AddressProvince } from "../value-objects/AddressProvince";
import { AddressStreet } from "../value-objects/AddressStreet";

export interface AddressJson {
    readonly city: string,
    readonly postCode: string,
    readonly province: string,
    readonly street: string
}

export class Address {
    constructor(
        readonly city: AddressCity,
        readonly postCode: AddressPostCode,
        readonly province: AddressProvince,
        readonly street: AddressStreet
    ) {}

    static fromJson(json: AddressJson): Address {
        return new Address(
            new AddressCity(json.city),
            new AddressPostCode(json.postCode),
            new AddressProvince(json.province),
            new AddressStreet(json.street)
        );
    }

    public toJson(): AddressJson {
        return {
            city: this.city.value,
            postCode: this.postCode.value,
            province: this.province.value,
            street: this.street.value
        }
    }

    public getSecondaryData(): string {
        return `${ this.postCode.value }, ${ this.city.value } (${ this.province.value })`;
    }
}